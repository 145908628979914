import {
  REQUEST_GET_PAGE_CONTENT,
  GET_PAGE_CONTENT_SUCCESS,
  GET_PAGE_CONTENT_ERROR,
  REQUEST_GET_PAGE_CHILDREN_MENUS,
  GET_PAGE_CHILDREN_MENUS_SUCCESS,
  GET_PAGE_CHILDREN_MENUS_ERROR,
  REQUEST_GET_PAGE_PARENT,
  GET_PAGE_PARENT_SUCCESS,
  GET_PAGE_PARENT_ERROR,
  REQUEST_GET_FOOTER_CONTENT,
  GET_FOOTER_CONTENT_SUCCESS,
  GET_FOOTER_CONTENT_ERROR,
  CLEAR_ERRORS,
} from '../constants/page';
import { callApi, getCookies, handleErrors } from '../../helpers/utils';
import { wpStructureLevels, wpDesigns, errorCodes } from '../../constants/constants';

const createLink = (link = '') => {
  if (!link || link === '') return '';
  const linkItems = link.split('fooldal');
  if (!linkItems || linkItems.length === 0) return link;
  return linkItems[linkItems.length - 1];
};

const getPartOfPath = (path = '', returnLast = true) => {
  if (!path || path === '') return 'fooldal';
  const pathItems = path.split('/').filter(value => value !== '');
  if (!pathItems || pathItems.length === 0) return 'fooldal';
  if (returnLast) {
    return pathItems[pathItems.length - 1];
  }
  return pathItems[0];
};

const getExactResult = (data = [], path = '') => {
  if (!data) return {};
  if (data.length === 1) return data[0];
  return data.filter(({ acf: customFields }) => {
    const { page_type: pageType } = customFields;
    const currentPageType = getPartOfPath(path, false);
    return pageType === currentPageType;
  })[0];
};

const checkPermission = (page = {}) => {
  if (!page) return false;
  const { permission } = getCookies();
  const requiredPermissions = page['simple-restrict-permission'] || [];
  if (requiredPermissions?.length) {
    return !!requiredPermissions.includes(parseInt(permission, 10));
  }
  return true;
};

const requestGetPageChildrenMenus = () => ({
  type: REQUEST_GET_PAGE_CHILDREN_MENUS,
});

const getPageChildrenMenusSuccess = menuItems => ({
  type: GET_PAGE_CHILDREN_MENUS_SUCCESS,
  menuItems,
});

const getPageChildrenMenusError = error => ({
  type: GET_PAGE_CHILDREN_MENUS_ERROR,
  error,
});

export const getPageChildrenMenus = id => async (dispatch) => {
  dispatch(requestGetPageChildrenMenus());
  try {
    const { REACT_APP_API_PREFIX: apiPrefix } = process.env;
    const { data = [] } = await callApi(
      {
        method: 'GET',
        url: `/wp-json/wp/v2/pages?parent=${id}&_fields=id,meta,menu_order,link,slug,content,excerpt,_meta,simple-restrict-permission&per_page=100`,
      },
      apiPrefix,
    );

    const menuItems = data.map(
      ({
        id: childId, meta, menu_order: order, link, slug, content, excerpt, _embed,
      }) => {
        const { _hu_post_title: nameHu, _en_post_title: nameEn } = meta;
        return {
          id: childId,
          slug,
          nameHu,
          nameEn: nameEn || nameHu,
          order,
          link: createLink(link),
          content,
          excerpt,
          _embed,
        };
      },
    );

    menuItems.sort(({ order: firstMenuOrder }, { order: secondMenuOrder }) => firstMenuOrder - secondMenuOrder);
    dispatch(getPageChildrenMenusSuccess(menuItems));
  } catch (error) {
    if (process.env.NODE_ENV === "development") console.error(error); // eslint-disable-line
    dispatch(getPageChildrenMenusError(handleErrors(error)));
  }
};

const requestGetPageParent = () => ({
  type: REQUEST_GET_PAGE_PARENT,
});

const getPageParentSuccess = parent => ({
  type: GET_PAGE_PARENT_SUCCESS,
  parent,
});

const getPageParentError = error => ({
  type: GET_PAGE_PARENT_ERROR,
  error,
});

export const getPageParent = id => async (dispatch) => {
  dispatch(requestGetPageParent());
  try {
    const { REACT_APP_API_PREFIX: apiPrefix } = process.env;
    const { data = [] } = await callApi(
      {
        method: 'GET',
        url: `/wp-json/wp/v2/pages/${id}?_fields=meta,link,slug,simple-restrict-permission`,
      },
      apiPrefix,
    );

    const { meta, link, slug } = data;
    const { _hu_post_title: nameHu, _en_post_title: nameEn } = meta;
    const parentItem = {
      id,
      nameHu,
      nameEn: nameEn || nameHu,
      slug,
      link: createLink(link),
    };

    dispatch(getPageParentSuccess(parentItem));
  } catch (error) {
    if (process.env.NODE_ENV === "development") console.error(error); // eslint-disable-line
    dispatch(getPageParentError(handleErrors(error)));
  }
};

const requestGetPageContent = () => ({
  type: REQUEST_GET_PAGE_CONTENT,
});

const getPageContentSuccess = currentPage => ({
  type: GET_PAGE_CONTENT_SUCCESS,
  currentPage,
});

const getPageContentError = error => ({
  type: GET_PAGE_CONTENT_ERROR,
  error,
});

export const getPageContent = (path = '') => async (dispatch) => {
  dispatch(requestGetPageContent());
  try {
    const { REACT_APP_API_PREFIX: apiPrefix } = process.env;
    const slug = getPartOfPath(path);
    const { data = [] } = await callApi(
      {
        method: 'GET',
        url: `/wp-json/wp/v2/pages?slug=${slug}`,
      },
      apiPrefix,
    );

    if (!data || data.length === 0) {
      dispatch(getPageContentError(errorCodes.NO_PAGE_DATA));
      return;
    }

    const page = getExactResult(data, path);

    if (!checkPermission(page)) {
      dispatch(getPageContentError(errorCodes.ACCESS_DENIED));
      return;
    }

    const {
      id: pageId,
      slug: pageSlug,
      status,
      content,
      parent: parentId,
      meta,
      acf,
      link,
    } = page;

    const {
      page_type: pageType = wpDesigns.emergency,
      structure_level: structureLevel = wpStructureLevels.firstLevel,
    } = acf;

    if (
      structureLevel === wpStructureLevels.firstLevel
      || structureLevel === wpStructureLevels.secondLevel
      || structureLevel === wpStructureLevels.thirdLevel
    ) {
      dispatch(getPageChildrenMenus(pageId));
    }

    if (
      structureLevel === wpStructureLevels.secondLevel
      || structureLevel === wpStructureLevels.thirdLevel
    ) {
      dispatch(getPageParent(parentId));
    }

    dispatch(
      getPageContentSuccess({
        pageId,
        slug: pageSlug,
        isPublished: status === 'publish',
        content,
        parentId,
        meta,
        pageType,
        structureLevel,
        link: createLink(link),
      }),
    );
  } catch (error) {
    if (process.env.NODE_ENV === "development") console.error(error); // eslint-disable-line
    dispatch(getPageContentError(handleErrors(error)));
  }
};

const requestGetFooterContent = () => ({
  type: REQUEST_GET_FOOTER_CONTENT,
});

const getFooterContentSuccess = footerPage => ({
  type: GET_FOOTER_CONTENT_SUCCESS,
  footerPage,
});

const getFooterContentError = error => ({
  type: GET_FOOTER_CONTENT_ERROR,
  error,
});

export const getFooterContent = () => async (dispatch) => {
  dispatch(requestGetFooterContent());
  try {
    const {
      REACT_APP_API_PREFIX: apiPrefix,
      REACT_APP_FOOTER_ID: footerId,
    } = process.env;
    const { data = {} } = await callApi(
      {
        method: 'GET',
        url: `/wp-json/wp/v2/pages/${footerId}`,
      },
      apiPrefix,
    );

    const { content, meta } = data;

    dispatch(getFooterContentSuccess({ content, meta }));
  } catch (error) {
    if (process.env.NODE_ENV === "development") console.error(error); // eslint-disable-line
    dispatch(getFooterContentError(handleErrors(error)));
  }
};

export const clearPageErrors = () => ({
  type: CLEAR_ERRORS,
});

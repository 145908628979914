import React from 'react';
import PropTypes from 'prop-types';

const Image = ({
  src,
  originalFormat = 'png',
  alt,
  className,
  pictureClassName = '',
}) => (
  <picture className={pictureClassName}>
    <source srcSet={`/images/${src}.webp`} type="image/webp" />
    <source
      srcSet={`/images/${src}.${originalFormat}`}
      type={originalFormat === 'png' ? 'image/png' : 'image/jpeg'}
    />
    <img
      src={`/images/${src}.${originalFormat}`}
      alt={alt}
      className={className}
    />
  </picture>
);

Image.propTypes = {
  src: PropTypes.string.isRequired,
  originalFormat: PropTypes.string,
  alt: PropTypes.string,
  className: PropTypes.string,
  pictureClassName: PropTypes.string,
};

export default Image;

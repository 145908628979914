/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import { Box, Container, Typography, Link } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { replaceVideoTag } from '../../helpers/utils';

import Header from '../Header/Header';
import useStyles from './ThirdLevelMobile.styles';

const ThirdLevelMobile = (props) => {
  const {
    currentPage = {},
    parentPage = {},
    pageType = 'baleseti',
    language,
    toggleLanguage,
    userIsLoggedIn,
    menuItems,
  } = props;
  const { meta } = currentPage;
  const { _hu_post_title: titleHu, _hu_post_content: contentHu, _en_post_title: titleEn, _en_post_content: contentEn } = meta;
  const { link: parentLink, nameHu: parentTitleHu, nameEn: parentTitleEn } = parentPage;
  const rightNav = { title: language === 'en' ? parentTitleEn : parentTitleHu, link: parentLink };
  const classes = useStyles({ pageType });

  return (
    <Container className={classes.root} maxWidth="xl" disableGutters>
      <Container className={classes.contentWrapper} maxWidth="xl">
        <Header pageType={pageType} rightNav={rightNav} isMobile language={language} toggleLanguage={toggleLanguage} userIsLoggedIn={userIsLoggedIn} />
        <Box className={classes.content}>
          <Typography className={classes.title} variant="h5">
            {language === 'en' ? titleEn : titleHu}
          </Typography>
          <Box>
            {menuItems.map(menuItem => (
              <Typography variant="subtitle2" key={menuItem.id}>
                <Link component={RouterLink} className={classes.link} to={menuItem.link}>
                  {language === 'en' ? menuItem.nameEn : menuItem.nameHu}
                </Link>
              </Typography>
            ))}
          </Box>
          <div className={classes.wpContent} dangerouslySetInnerHTML={{ __html: language === 'en' ? replaceVideoTag(contentEn) : replaceVideoTag(contentHu) }} />
        </Box>
      </Container>
    </Container>
  );
};

ThirdLevelMobile.propTypes = {
  currentPage: PropTypes.object.isRequired,
  parentPage: PropTypes.object.isRequired,
  pageType: PropTypes.string.isRequired,
  language: PropTypes.string,
  toggleLanguage: PropTypes.func,
  userIsLoggedIn: PropTypes.bool,
  menuItems: PropTypes.array,
};

export default ThirdLevelMobile;

import Cookies from 'js-cookie';
import {
  REQUEST_GET_SESSION,
  GET_SESSION_SUCCESS,
  GET_SESSION_ERROR,
  SET_HAS_SESSION_FROM_COOKIE,
  REQUEST_CHECK_SESSION,
  GET_SESSION_CHECK_SUCCESS,
  GET_SESSION_CHECK_ERROR,
  CLEAR_ERRORS,
} from '../constants/session';
import {
  callApi,
  getCookies,
  handleErrors,
} from '../../helpers/utils';
import { errorCodes } from '../../constants/constants';

const requestGetSession = () => ({
  type: REQUEST_GET_SESSION,
});

const getSessionSuccess = () => ({
  type: GET_SESSION_SUCCESS,
});

const getSessionError = error => ({
  type: GET_SESSION_ERROR,
  error,
});

export const getSession = () => async (dispatch) => {
  const { token } = getCookies();
  if (token) return;
  dispatch(requestGetSession());
  try {
    const {
      REACT_APP_API_PREFIX: apiPrefix,
      REACT_APP_API_USER: apiUser,
      REACT_APP_API_PASS: apiPass,
    } = process.env;
    const { data = {} } = await callApi(
      {
        method: 'POST',
        url: '/wp-json/jwt-auth/v1/token',
        data: {
          username: apiUser,
          password: apiPass,
        },
      },
      apiPrefix,
    );
    const { token: newToken, user_permission: userPermission } = data;
    Cookies.set('_ogysebpSession', newToken, 7);
    Cookies.set('_ogysebpPermission', userPermission, 7);
    Cookies.set('wordpress_test_cookie', 'WP Cookie check', 7);
    dispatch(getSessionSuccess());
  } catch (error) {
    if (process.env.NODE_ENV === "development") console.error(error); // eslint-disable-line
    dispatch(getSessionError(handleErrors(error)));
  }
};

const requestSessionCheck = () => ({
  type: REQUEST_CHECK_SESSION,
});

const getSessionCheckSuccess = () => ({
  type: GET_SESSION_CHECK_SUCCESS,
});

const getSessionCheckError = error => ({
  type: GET_SESSION_CHECK_ERROR,
  error,
});

export const checkSession = () => async (dispatch) => {
  dispatch(requestSessionCheck());
  try {
    const { REACT_APP_API_PREFIX: apiPrefix } = process.env;
    const { data = {} } = await callApi(
      {
        method: 'POST',
        url: '/wp-json/jwt-auth/v1/token/validate',
      },
      apiPrefix,
    );
    const { code = '' } = data;
    if (code === 'jwt_auth_valid_token') {
      dispatch(getSessionCheckSuccess());
      return;
    }
    dispatch(getSessionCheckError(errorCodes.INVALID_TOKEN));
  } catch (error) {
    if (process.env.NODE_ENV === "development") console.error(error); // eslint-disable-line
    dispatch(getSessionCheckError(handleErrors(error)));
  }
};

export const setHasSessionFromCookie = (hasSession = false) => ({
  type: SET_HAS_SESSION_FROM_COOKIE,
  hasSession,
});

export const clearSessionErrors = () => ({
  type: CLEAR_ERRORS,
});

/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { Box, Container, IconButton, Link, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import Image from '../Image/Image';
import Header from '../Header/Header';
//import useStyles from './BalesetiSecondLevel.styles';
//import FirstLevelMobile from './FirstLevelMobile';
import Balesetimainmenu from '../balesetimainmenu/balesetimainmenu';
import { getIntros } from '../../redux/actions/balesetpage';
//import { login } from '../../redux/actions/user';
import { replaceVideoTag } from '../../helpers/utils';
import BalesetiBackButton from '../balesetiBackButton/BalesetiBackButton';
import './BalesetiSecondLevel.css';
import BalesetiMobileMenu from '../balesetiMobileMenu/BalesetiMobileMenu';

const BalesetiSecondLevel = (props) => {
  const { menuItems = [], pageType = 'baleseti', language } = props;
  const history = useHistory();
  const [selectedPage, setSelectedPage] = useState('gyermekeknek');
  //const classes = useStyles(location.pathname);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.md));
  //const altText = pageType === 'baleseti' ? 'gyermekbaleseti' : 'gyermeksürgősségi';

  const path = location.pathname.split('/').filter(path => path != '');
  let backpath = '/';
  for (let index = 0; index < path.length - 1; index++) {
    backpath += path[index] + '/';
  }
  const submenupath = path[path.length - 1];
  let backgroundimageUrl;
  switch (submenupath) {
    case "gyakori-balesettipusok":
      backgroundimageUrl = '/images/gyakoribalesetHeader.png';
      break;
    case "szulok-nevelok":
      backgroundimageUrl = '/images/szuloknevelokHeader.png';
      break;
    case "baleseti-szakmai":
      backgroundimageUrl = '/images/balesetiSzakmaiHeader.png';
      break;
    case "kisiskolasok":
      backgroundimageUrl = '/images/kisiskolasok.png';
      break;
    case "vedonok-gyermekorvosok":
      backgroundimageUrl = '/images/vedonokgyermekorvosok.png';
      break;
    case "epidemiologia":
      backgroundimageUrl = '/images/epidemiologia.png';
      break;
    case "irodalomfigyeles":
      backgroundimageUrl = '/images/irodalomfigyeles.png';
      break;
    default:
      backgroundimageUrl = '/images/best_headphones_lifestyle.jpg';
      break;
  }

  if (submenupath == 'baleseti-szakmai' && props.userIsLoggedIn != null && !props.userIsLoggedIn) {
    history.push({
      pathname: '/login',
      state: { redirectPath: '/baleseti/baleseti-szakmai' },
    });
  }

  //const handleClick = () => history.goBack();
  //const handleMouseOver = selected => setSelectedPage(selected.toLowerCase());

  useEffect(() => {
    props.getIntros(location.pathname);
    //props.getSubMenus(556);     
  }, []);

  //const properties = props;
  if (!isMobile) {
    return (
      <div>
        <Balesetimainmenu />
        <div className="Header">
          <div className="HeaderContent">
            <div className="main">
              <h1 className="mainImageContentHeader">{(props.Intros != undefined ? props.Intros.meta._hu_post_title : '')}</h1>
              <p className="mainImageContentBody" dangerouslySetInnerHTML={{ __html: props.Intros != undefined ? replaceVideoTag(props.Intros.content.rendered) : '' }}></p>
            </div>
            <div className="mainImage">
              <img src={backgroundimageUrl} />
            </div>
          </div>
        </div>

        <div className="boxRow">

          {props.subMenuItems.map(Intro => (
            <div className="Box">
              <img className="BoxImage" src={(Intro._embedded['wp:featuredmedia'] != undefined ? Intro._embedded['wp:featuredmedia'][0].source_url : '')} />
              <h1 className="BoxTitle">{Intro.nameHu}</h1>
              <p className="BoxDate">{Intro.date.substr(0, 10).replaceAll("-", ".")}</p>
              <p className="BoxContent" dangerouslySetInnerHTML={{ __html: replaceVideoTag(Intro.excerpt.rendered) }}></p>
              <a className="BoxLink" href={Intro.link}>Bővebben</a>
            </div>
          ))}

        </div>
        <BalesetiBackButton targetPath={backpath} />
      </div>
    );
  }
  else {
    return (
      <div>
        <BalesetiMobileMenu />
        <div className="HeaderMobil">
          <div className="HeaderContentMobil">
            <div className="mainMobil">
              <h1 className="mainImageContentHeaderMobil">{(props.Intros != undefined ? props.Intros.meta._hu_post_title : '')}</h1>
              <p className="mainImageContentBodyMobil" dangerouslySetInnerHTML={{ __html: props.Intros != undefined ? replaceVideoTag(props.Intros.content.rendered) : '' }}></p>
            </div>
          </div>
        </div>
        {/* <div className="mainImageMobil">
                <img src={backgroundimageUrl}/>
          </div> */}
        <div className="boxRowMobil">

          {props.subMenuItems.map(Intro => (
            <div className="BoxMobil">
              <img className="BoxImageMobil" src={(Intro._embedded['wp:featuredmedia'] != undefined ? Intro._embedded['wp:featuredmedia'][0].source_url : '')} />
              <h1 className="BoxTitleMobil">{Intro.nameHu}</h1>
              <p className="BoxDateMobil">{Intro.date.substr(0, 10).replaceAll("-", ".")}</p>
              <p className="BoxContentMobil" dangerouslySetInnerHTML={{ __html: replaceVideoTag(Intro.excerpt.rendered) }}></p>
              <a className="BoxLinkMobil" href={Intro.link}>Bővebben</a>
            </div>
          ))}

        </div>
        <BalesetiBackButton targetPath={backpath} />
      </div>
    )
  }

};

BalesetiSecondLevel.propTypes = {
  Intros: PropTypes.string.isRequired,
  subMenuItems: PropTypes.array.isRequired
};

const mapStateToProps = ({ balesetipageReducer, userReducer }, ownProps) => {
  return {
    Intros: balesetipageReducer.Intros,
    subMenuItems: balesetipageReducer.subMenuItems,
    userIsLoggedIn: userReducer.userIsLoggedIn
  }
}

const mapDispatchToProps = () => {
  return {
    getIntros
  }
}

export default connect(mapStateToProps, mapDispatchToProps())(BalesetiSecondLevel);

import Cookies from 'js-cookie';
import {
  REQUEST_LOGIN,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  SET_HAS_USER_SESSION_FROM_COOKIE,
  REQUEST_REGISTER,
  REGISTER_SUCCESS,
  REGISTER_ERROR,
  CLEAR_ERRORS,
} from '../constants/user';
import { callApi, removeCookies, handleErrors } from '../../helpers/utils';
import { errorCodes } from '../../constants/constants';

const requestLogin = () => ({
  type: REQUEST_LOGIN,
});

const loginSuccess = userName => ({
  type: LOGIN_SUCCESS,
  userName,
});

const loginError = error => ({
  type: LOGIN_ERROR,
  error,
});

export const login = (email, password) => async (dispatch) => {
  dispatch(requestLogin());
  try {
    removeCookies();
    const { REACT_APP_API_PREFIX: apiPrefix } = process.env;
    const { data = {} } = await callApi(
      {
        method: 'POST',
        url: '/wp-json/jwt-auth/v1/token',
        data: {
          username: email,
          password,
        },
      },
      apiPrefix,
    );
    const { token: newToken, user_permission: userPermission, user_display_name: userName } = data;
    Cookies.set('_ogysebpSession', newToken, 7);
    Cookies.set('_ogysebpPermission', userPermission, 7);
    Cookies.set('_ogysebpUserName', userName, 7);
    dispatch(loginSuccess(userName));
  } catch (error) {
    if (process.env.NODE_ENV === "development") console.error(error); // eslint-disable-line
    dispatch(loginError(handleErrors(error)));
  }
};

export const setHasUserSessionFromCookie = (hasSession = false, userName) => ({
  type: SET_HAS_USER_SESSION_FROM_COOKIE,
  hasSession,
  userName,
});

const requestRegister = () => ({
  type: REQUEST_REGISTER,
});

const registerSuccess = () => ({
  type: REGISTER_SUCCESS,
});

const registerError = error => ({
  type: REGISTER_ERROR,
  error,
});

export const register = (
  name,
  email,
  password,
  qualificationType,
  workplace,
) => async (dispatch) => {
  dispatch(requestRegister());
  try {
    const { REACT_APP_API_PREFIX: apiPrefix } = process.env;
    const { data = {} } = await callApi(
      {
        method: 'POST',
        url: '/wp-json/wp/v2/users',
        data: {
          username: email,
          name,
          email,
          password,
          meta: {
            'simple-restrict-szakmai-jog': 'yes',
            'type-of-qualification-examination': qualificationType.replace(/ /g, '_'),
            'work-place': workplace.replace(/ /g, '_'),
          },
        },
      },
      apiPrefix,
    );
    const { id: newUserId } = data;
    if (!newUserId) {
      dispatch(registerError(errorCodes.REST_CANNOT_CREATE_USER));
      return;
    }
    dispatch(registerSuccess());
  } catch (error) {
    if (process.env.NODE_ENV === "development") console.error(error); // eslint-disable-line
    const errorCode = handleErrors(error);
    dispatch(registerError(errorCode));
  }
};

export const clearUserErrors = () => ({
  type: CLEAR_ERRORS,
});

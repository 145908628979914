import { connect } from 'react-redux';
import Session from '../components/Session/Session';
import { clearPageErrors } from '../redux/actions/page';
import { checkSession, clearSessionErrors, getSession, setHasSessionFromCookie } from '../redux/actions/session';
import { clearUserErrors, setHasUserSessionFromCookie } from '../redux/actions/user';

const mapStateToProps = ({ sessionReducer }) => ({
  hasSession: sessionReducer.hasSession,
  isSessionFetching: sessionReducer.isFetching,
  numOfTries: sessionReducer.numOfTries,
});

const mapDispatchToProps = dispatch => ({
  getSession: () => dispatch(getSession()),
  setHasSessionFromCookie: hasSession => dispatch(setHasSessionFromCookie(hasSession)),
  setHasUserSessionFromCookie: (hasSession, userName) => dispatch(setHasUserSessionFromCookie(hasSession, userName)),
  checkSession: () => dispatch(checkSession()),
  clearAllErrors: () => {
    dispatch(clearPageErrors());
    dispatch(clearUserErrors());
    dispatch(clearSessionErrors());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Session);

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: '100vh',
    position: 'relative',
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    zIndex: theme.zIndex.drawer,
    minHeight: '100vh',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(10),
    maxWidth: 400,
    padding: theme.spacing(0, 4),
  },
  title: {
    color: theme.palette.primary.dark,
    marginBottom: theme.spacing(4),
  },
  notRegistered: {
    color: theme.palette.primary.dark,
    textDecoration: 'underline',
    marginTop: theme.spacing(15),
  },
  errorMessage: {
    color: theme.palette.secondary.dark,
  },
  outlinedInput: {
    padding: theme.spacing(2),
  },
  notchedOutline: {
    borderColor: theme.palette.primary.dark,
    borderWidth: 4,
    borderRadius: 30,
  },
  notchedOutlineFocused: {},
  textField: {
    color: theme.palette.primary.dark,
    borderColor: theme.palette.primary.dark,
    margin: theme.spacing(1, 0),
    '&$notchedOutlineFocused $notchedOutline': {
      borderColor: theme.palette.primary.dark,
    },
    '&:hover $notchedOutline': {
      borderColor: theme.palette.primary.dark,
    },
    '&$outlinedInputError $notchedOutline': {
      borderColor: theme.palette.secondary.dark,
    },
  },
  outlinedInputError: {
    color: theme.palette.secondary.main,
  },
  submitButton: {
    color: theme.palette.common.white,
    borderRadius: 30,
    backgroundColor: theme.palette.primary.dark,
    width: '100%',
  },
  goBackButton: {
    marginTop: theme.spacing(1),
    color: theme.palette.primary.dark,
    borderColor: theme.palette.primary.dark,
    borderWidth: 2,
    borderRadius: 30,
    width: '100%',
  },
}));

export default useStyles;

import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router';
import { getCookies } from '../../helpers/utils';
import Loading from '../Loading/Loading';
import NotFound from '../NotFound/NotFound';

const Session = ({
  children,
  hasSession,
  setHasSessionFromCookie,
  setHasUserSessionFromCookie,
  getSession,
  isSessionFetching,
  checkSession,
  numOfTries,
  clearAllErrors,
}) => {
  const location = useLocation();

  useEffect(() => {
    clearAllErrors();
  }, [location, clearAllErrors]);

  useEffect(() => {
    if (!hasSession && numOfTries < 3) {
      const { token, permission, userName } = getCookies();
      if (!token) {
        getSession();
      } else {
        checkSession();
      }
      const { REACT_APP_USER_PERMISSION: userPermission } = process.env;
      const hasAPISession = token !== '' && permission !== '';
      const hasUserSession = token !== '' && parseInt(permission, 10) === parseInt(userPermission, 10);
      setHasSessionFromCookie(hasAPISession);
      setHasUserSessionFromCookie(hasUserSession, userName);
    }
  }, [
    numOfTries,
    getSession,
    hasSession,
    setHasSessionFromCookie,
    setHasUserSessionFromCookie,
    checkSession,
  ]);

  if (isSessionFetching) return <Loading />;
  if (!isSessionFetching && !hasSession) return <NotFound />;

  return (
    <>
      {children}
    </>
  );
};

Session.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  hasSession: PropTypes.bool,
  setHasSessionFromCookie: PropTypes.func.isRequired,
  setHasUserSessionFromCookie: PropTypes.func.isRequired,
  getSession: PropTypes.func.isRequired,
  isSessionFetching: PropTypes.bool.isRequired,
  checkSession: PropTypes.func.isRequired,
  numOfTries: PropTypes.number.isRequired,
  clearAllErrors: PropTypes.func.isRequired,
};

export default Session;

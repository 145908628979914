import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: '30vh',
    backgroundColor: theme.palette.primary.light,
    position: 'relative',
    color: theme.palette.primary.dark,
  },
  logo: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    maxWidth: 350,
    maxHeight: '80%',
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(2, 10),
    [theme.breakpoints.down('lg')]: {
      paddingRight: 280,
    },
  },
  leftContent: {
    fontSize: '0.65rem',
    [theme.breakpoints.up('lg')]: {
      fontSize: theme.typography.subtitle1.fontSize,
    },
  },
  rightContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  phoneTitle: {
    [theme.breakpoints.up('lg')]: {
      fontSize: theme.typography.h5.fontSize,
    },
  },
  phoneNumber: {
    fontWeight: 900,
    [theme.breakpoints.up('lg')]: {
      fontSize: theme.typography.h5.fontSize,
    },
  },
  heimPalLogo: {
    margin: theme.spacing(2, 0),
    height: 30,
  },
  languageButton: {
    borderRadius: 30,
    padding: 0,
    height: 30,
    width: 100,
    border: `3px solid ${theme.palette.primary.dark}`,
    color: theme.palette.primary.dark,
    '&:hover': {
      border: `3px solid ${theme.palette.primary.dark}`,
    },
    fontWeight: theme.typography.fontWeightBold,
    [theme.breakpoints.up('lg')]: {
      fontSize: theme.typography.h6.fontSize,
    },
  },
}));

export default useStyles;

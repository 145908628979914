export const REQUEST_GET_MENU = 'REQUEST_GET_MENU';
export const GET_MENU_SUCCESS = 'GET_MENU_SUCCESS';
export const GET_MENU_ERROR = 'GET_MENU_ERROR';

export const REQUEST_GET_INTROS = 'REQUEST_GET_INTROS';
export const GET_GET_INTROS_SUCCESS = 'GET_GET_INTROS_SUCCESS';
export const GET_GET_INTROS_ERROR = 'GET_GET_INTROS_ERROR';

export const REQUEST_GET_SUBMENUS = 'REQUEST_GET_SUBMENUS';
export const GET_SUBMENUS_SUCCESS = 'GET_SUBMENUS_SUCCESS';
export const GET_SUBMENUS_ERROR = 'GET_SUBMENUS_ERROR';

export const REQUEST_GET_BALESETIPAGE = 'REQUEST_GET_BALESETIPAGE';
export const GET_BALESETIPAGE_SUCCESS = 'GET_BALESETIPAGE_SUCCESS';
export const GET_BALESETIPAGE_ERROR = 'GET_BALESETIPAGE_ERROR';

export const REQUEST_SEARCH_BALESETIPAGE = 'REQUEST_SEARCH_BALESETIPAGE';
export const SEARCH_BALESETIPAGE_SUCCESS = 'SEARCH_BALESETIPAGE_SUCCESS';
export const SEARCH_BALESETIPAGE_ERROR = 'SEARCH_BALESETIPAGE_ERROR';

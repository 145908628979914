/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import { Box, Container, IconButton, Link, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import Image from '../Image/Image';
import Header from '../Header/Header';
//import useStyles from './BalesetiFirstLevel.styles';
//import FirstLevelMobile from './FirstLevelMobile';
import Balesetimainmenu from '../balesetimainmenu/balesetimainmenu';
import { SearchBalesetiPageContent } from '../../redux/actions/balesetpage';
import { replaceVideoTag } from '../../helpers/utils';
import BalesetiBackButton from '../balesetiBackButton/BalesetiBackButton';
import BalesetiMobileMenu from '../balesetiMobileMenu/BalesetiMobileMenu';
import './BalesetiSearch.css';
import Search from '../Search/Search'; '../Search/Search';

const BalesetiSearch = (props) => {
  //const { menuItems = [], pageType = 'baleseti', language } = props;
  const history = useHistory();
  //const [selectedPage, setSelectedPage] = useState('gyermekeknek');
  //const classes = useStyles({ pageType, selectedPage });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.md));
  //const altText = pageType === 'baleseti' ? 'gyermekbaleseti' : 'gyermeksürgősségi';
  useLocation();
  const handleClick = () => history.goBack();
  const handleMouseOver = selected => setSelectedPage(selected.toLowerCase());

  
  
  //if (isMobile) return <FirstLevelMobile {...props} />;

  //useEffect(() => {    
  // props.SearchBalesetiPageContent();     
  //}, []);
  
  //const [searchTerm, setValue] = useState('');
  //const searchTerm = '';
  const backUrl = location.search.substring(location.search.lastIndexOf('?backurl=')+9,location.search.lastIndexOf('&'));
  const term = location.search.substring(location.search.lastIndexOf('&term=')+6);
  const path = location.pathname.split('/').filter(path => path != '');
  //console.log(currentSearchTerm);
  //const Search = () => {
  // //console.log(searchTerm);
  //  history.push('/kereses/'+searchTerm);  
  //  history.go(); 
  //}
//
  //const handleChange = event => {
  // setValue(event.target.value);
//
  // //console.log('value is:', event.target.value);
  //};

  //const handleKeyDown = (event) => {
  //  if (event.key === 'Enter') {
  //    Search();
  //  }
  //}
//
  //useEffect(() => {    
  //  (currentSearchTerm == 'kereses' ? '' : props.SearchBalesetiPageContent(currentSearchTerm));
  //  console.log(props.searchResult)
  //}, []);
//const searchRoute = '/balesetikereses/';
  
        if(!isMobile){
          return (        
            <div>
              <Balesetimainmenu />
              <Search data={{currentSearchTerm: term,searchRoute: '/balesetikereses/?backurl='+backUrl+'&term='}}/>
              <BalesetiBackButton targetPath={backUrl} />
            </div>
          );
        }
        else
        {
          return (        
            <div>   
                <BalesetiMobileMenu/> 
                <Search data={{currentSearchTerm: term,searchRoute: '/balesetikereses/?backurl='+backUrl+'&term='}}/>
                <BalesetiBackButton targetPath={backUrl} />
            </div>
          );
        }
            
      
      return (<div>Kecskebéka</div>);
};

BalesetiSearch.propTypes = {
  searchResult: PropTypes.array, 
  searchTerm: PropTypes.string
};

const mapStateToProps = ({ balesetipageReducer, languageReducer, userReducer }, ownProps) => {
  return {    
    searchResult: balesetipageReducer.searchResult,
    searchTerm: '',
  }
}

const mapDispatchToProps = () => {
  return {
    SearchBalesetiPageContent
  }
}

export default connect(mapStateToProps, mapDispatchToProps())(BalesetiSearch);
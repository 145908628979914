import {
  REQUEST_GET_SESSION,
  GET_SESSION_SUCCESS,
  GET_SESSION_ERROR,
  SET_HAS_SESSION_FROM_COOKIE,
  REQUEST_CHECK_SESSION,
  GET_SESSION_CHECK_SUCCESS,
  GET_SESSION_CHECK_ERROR,
  CLEAR_ERRORS,
} from '../constants/session';

const initialState = {
  isFetching: false,
  errorMessage: null,
  hasSession: false,
  numOfTries: 0,
};

const sessionReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_GET_SESSION: {
      return { ...state, isFetching: true };
    }
    case GET_SESSION_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        errorMessage: null,
        hasSession: true,
        numOfTries: 0,
      };
    }
    case GET_SESSION_ERROR: {
      const { error } = action;
      return {
        ...state,
        isFetching: false,
        errorMessage: error,
        hasSession: false,
        numOfTries: state.numOfTries + 1,
      };
    }
    case SET_HAS_SESSION_FROM_COOKIE: {
      const { hasSession } = action;
      return {
        ...state,
        hasSession,
      };
    }
    case REQUEST_CHECK_SESSION: {
      return { ...state, isFetching: true };
    }
    case GET_SESSION_CHECK_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        errorMessage: null,
        hasSession: true,
        numOfTries: 0,
      };
    }
    case GET_SESSION_CHECK_ERROR: {
      const { error } = action;
      return {
        ...state,
        isFetching: false,
        errorMessage: error,
        hasSession: false,
        numOfTries: state.numOfTries + 1,
      };
    }
    case CLEAR_ERRORS: {
      return {
        ...state,
        errorMessage: null,
      };
    }
    default:
      return state;
  }
};

export default sessionReducer;

/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, useTheme, useMediaQuery, Link } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { useHistory } from 'react-router';
import { replaceVideoTag } from '../../helpers/utils';
import Header from '../Header/Header';
import SurgossegiSearchMobile from './surgossegiSearchMobile';
import useStyles from './surgossegiSearch.styles';
import LowerLevelLayout from '../Layouts/LowerLevelLayout/LowerLevelLayout';
import Balesetimainmenu from '../balesetimainmenu/balesetimainmenu';
import './surgossegiSearch.css';
import Search from '../Search/Search'; '../Search/Search';

const SurgossegiSearch = (props) => {
  const history = useHistory();
  
  const { currentPage = {}, parentPage = {}, pageType = 'baleseti', language, menuItems } = props;
  const { meta } = currentPage;
  //const { _hu_post_title: titleHu, _hu_post_content: contentHu, _en_post_title: titleEn, _en_post_content: contentEn } = meta;
  //const { link: parentLink, nameHu: parentTitleHu, nameEn: parentTitleEn } = parentPage;
  //const rightNav = { title: language === 'en' ? parentTitleEn : parentTitleHu, link: parentLink };
  
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.md));
  const classes = useStyles({ pageType });
  const backUrl = location.search.substring(location.search.lastIndexOf('?backurl=')+9,location.search.lastIndexOf('&'));
  const term = location.search.substring(location.search.lastIndexOf('&term=')+6);
  const path = location.pathname.split('/').filter(path => path != '');
  const rightNav = { title: '', link: backUrl };
  let backpath = '/';
  for (let index = 0; index < path.length - 1; index++) {
    backpath += path[index] + '/';
  }
  const handleClick = () => history.push(backpath);

  if (isMobile) return <SurgossegiSearchMobile {...props} />;  
  
    return (
      <LowerLevelLayout pageType={pageType}>
        <Header pageType={pageType} rightNav={rightNav} />
        <Box className={classes.content}>
          <Typography className={classes.title} variant="h2">
            Keresés
          </Typography>
          <Box>
            {/* {menuItems.map(menuItem => (
              <Typography variant="subtitle2" key={menuItem.id}>
                <Link component={RouterLink} className={classes.link} to={menuItem.link}>
                  {language === 'en' ? menuItem.nameEn : menuItem.nameHu}
                </Link>
              </Typography>
            ))} */}
          </Box>
          {/* <div className={classes.wpContent} dangerouslySetInnerHTML={{ __html: language === 'en' ? replaceVideoTag(contentEn) : replaceVideoTag(contentHu) }} /> */}
          <Search data={{currentSearchTerm: term,searchRoute: '/surgossegikereses/?backurl='+backUrl+'&term='}}/>
        </Box>        
      </LowerLevelLayout>
    );
  }

SurgossegiSearch.propTypes = {
  currentPage: PropTypes.object.isRequired,
  parentPage: PropTypes.object.isRequired,
  pageType: PropTypes.string.isRequired,
  language: PropTypes.string,
  menuItems: PropTypes.array,
};

export default SurgossegiSearch;

/* eslint-disable */
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  //Accident
  mainImageContainer: {    
    maxWidth: '100%',
    overflow: 'hidden'
  },
  mainImage: {
     height: '200px',
     width: '50%'
  }, 
  MenuContainer: {
    height: '100px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'   
  },
  upperMenuLink: {
    color: 'rgba(0,0,0,0.6)',
    fontWeight: '400',
    fontSize: '16px',
    paddingLeft: '30px',
    marginTop: '20px'
 },
 Logo: {
  height: '40px'
 }, 
 Message: {
  fontWeight: '600',
  textTransform: 'uppercase',
  fontSize: '36px',
  color: '#4d7d97',
  letterSpacing: '4px',
  textAlign: 'center',
 },
 boxRow: {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'  
 },
 Box: {
  height: '430px',
  width: '300px',  
  marginLeft: '50px',
  marginBottom: '30px'
 },
 BoxImage: {
   width: '100%'
 },
 BoxTitle: {
  fontWeight: '700',
  fontVariant: 'small-caps',
  fontSize: '30px',
  lineHeight: '1.5em',
  textAlign: 'left',
 },
 BoxContent: {
  fontSize: '14px',
  color: '#666',
  lineHeight: '1.7em'
 },
  //Sos
  root: {
    height: '100vh',
    backgroundImage: props => `url("/images/${props.selectedPage}_${props.pageType}.png")`,
    backgroundSize: 'cover',
    position: 'relative',
  },
  cornerImage: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    maxHeight: '70%',
    zIndex: theme.zIndex.drawer,
    display: 'flex',
    alignItems: 'center',
  },
  fadeImage: {
    height: '100vh',
    maxWidth: '100%',
    position: 'absolute',
    zIndex: theme.zIndex.appBar,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    zIndex: theme.zIndex.drawer,
    minHeight: '100vh',
    padding: theme.spacing(0, 10.5),
  },
  backButton: {
    width: 40,
    height: 40,
    marginLeft: -10,
    '& img': {
      height: 25,
    },
  },
  links: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 'auto',
    marginBottom: theme.spacing(10),
  },
  link: {
    fontSize: '34px',
    color: props => (props.pageType === 'baleseti'
      ? theme.palette.primary.main : theme.palette.secondary.main),
    textDecoration: 'none',
    '&:active,&:hover,&:visited': {
      textDecoration: 'none',
    },
    '&:hover': {
      color: props => (props.pageType === 'baleseti'
        ? theme.palette.primary.dark : theme.palette.secondary.dark),
    },
  }  
}));

export default useStyles;

import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import Login from '../components/Login/Login';
import toggleLanguage from '../redux/actions/language';
import { login } from '../redux/actions/user';

const mapStateToProps = ({ userReducer, languageReducer }) => ({
  isFetching: userReducer.isFetching,
  errorMessage: userReducer.errorMessage,
  userIsLoggedIn: userReducer.userIsLoggedIn,
  language: languageReducer.language,
});

const mapDispatchToProps = dispatch => ({
  login: (email, password) => dispatch(login(email, password)),
  toggleLanguage: lang => dispatch(toggleLanguage(lang)),
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(Login);

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.primary.light,
    position: 'relative',
    color: theme.palette.primary.dark,
  },
  logo: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    maxWidth: 200,
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2, 2),
    width: '100%',
  },
  topContent: {
    fontSize: '0.65rem',
    width: '100%',
  },
  bottomContent: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  phoneNumber: {
    fontWeight: 900,
  },
  heimPalLogo: {
    margin: theme.spacing(2, 0),
    height: 30,
  },
}));

export default useStyles;

/* eslint-disable */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { Box, Container, IconButton, Link, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import Image from '../Image/Image';
import Header from '../Header/Header';
import useStyles from './FirstLevel.styles';
import FirstLevelMobile from './FirstLevelMobile';
import Balesetimainmenu from '../balesetimainmenu/balesetimainmenu';
import './FirstLevel.css';

const FirstLevel = (props) => {
  const { menuItems = [], pageType = 'baleseti', language } = props;
  const history = useHistory();
  const [selectedPage, setSelectedPage] = useState('gyermekeknek');
  const classes = useStyles({ pageType, selectedPage });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.md));
  const altText = pageType === 'baleseti' ? 'gyermekbaleseti' : 'gyermeksürgősségi';

  const path = location.pathname.split('/').filter(path => path != '');
  let backpath = '/';
  for (let index = 0; index < path.length - 1; index++) {
    backpath += path[index] + '/';
  }
  const handleClick = () => history.push(backpath);
  const handleMouseOver = selected => setSelectedPage(selected.toLowerCase());

  if (isMobile) return <FirstLevelMobile {...props} />;

  const properties = props;

  if (props.pageType == 'baleseti') {
    return (
      <div>
        <Balesetimainmenu />
        <div className={classes.mainImageContainer}>
          <img className={classes.MainImage} src="/images/Montazs_big.jpg" alt={altText} />
        </div>
        <h1 className={classes.Message}>Minden súlyos gyermekbaleset megelőzhető!</h1>

        <div className={classes.boxRow}>
          <div className={classes.Box}>
            <img className={classes.BoxImage} src="/images/bigstock-Toddler-Boy-First-Time-N-His-L-395951978-980x653.jpg" />
            <h1 className={classes.BoxTitle}>Szülők, nevelők</h1>
            <span className={classes.BoxContent}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</span>
          </div>

          <div className={classes.Box}>
            <img className={classes.BoxImage} src="/images/bigstock-The-Boy-Climbs-Out-The-Open-Wi-342630121-scaled.jpg" />
            <h1 className={classes.BoxTitle}>Kisiskolások</h1>
            <span className={classes.BoxContent}>Te már tudsz olvasni! Ha ide kattintasz, arról tájékozódhatsz, hogy hogyan kerülheted el a baleseteket játék, sport, szórakozás közben.</span>
          </div>

          <div className={classes.Box}>
            <img className={classes.BoxImage} src="/images/bigstock-Extreme-Sports-Motorcycle-Jum-304863877-scaled.jpg" />
            <h1 className={classes.BoxTitle}>Fiatalok</h1>
            <span className={classes.BoxContent}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</span>
          </div>
        </div>

        <div className={classes.boxRow}>
          <div className={classes.Box}>
            <img className={classes.BoxImage} src="/images/bigstock-Child-Safety-At-Home-Concept-230733043-scaled.jpg" />
            <h1 className={classes.BoxTitle}>Gyakori balesettípusok</h1>
            <span className={classes.BoxContent}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</span>
          </div>

          <div className={classes.Box}>
            <img className={classes.BoxImage} src="/images/bigstock-Children-Doing-Chemical-Resear-327508027-scaled.jpg" />
            <h1 className={classes.BoxTitle}>Oktatás, tudomány</h1>
            <span className={classes.BoxContent}>elemzett adatok<br />gyermekbiztonsági előadás ppt-k<br />gyermekbiztonsági szakirodalom összefoglalók magyarul</span>
          </div>

          <div className={classes.Box}>
            <img className={classes.BoxImage} src="/images/books.jpg" />
            <h1 className={classes.BoxTitle}>Kiadványok</h1>
            <span className={classes.BoxContent}>Gyermekbiztonsági kézikönyv szülőknek<br />WHO</span>
          </div>
        </div>
      </div>
    );
  }
  else {
    return (
      <Container className={classes.root} maxWidth={false} disableGutters>
        <Image className={classes.fadeImage} src={`${selectedPage}_${pageType}_atmenet`} alt={altText} />
        <Image className={classes.cornerImage} src={`first_level_corner_${pageType}`} alt={altText} />
        <Container className={classes.content} maxWidth={false}>
          <Header pageType={pageType} />
          <Box className={classes.links}>
            <IconButton className={classes.backButton} onClick={handleClick}>
              <Image src={`arrow_${pageType}`} alt={altText} />
            </IconButton>
            {menuItems.map(menuItem => (
              <Typography variant="h3" key={menuItem.id}>
                <Link
                  component={RouterLink}
                  onMouseOver={() => handleMouseOver(menuItem.slug)}
                  className={classes.link}
                  to={menuItem.link}
                >
                  {language === 'en' ? menuItem.nameEn : menuItem.nameHu}
                </Link>
              </Typography>
            ))}
          </Box>
        </Container>
      </Container>
    );
  }
};

FirstLevel.propTypes = {
  menuItems: PropTypes.array.isRequired,
  pageType: PropTypes.string.isRequired,
  language: PropTypes.string,
};

export default FirstLevel;

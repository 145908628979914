import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100vh',
    position: 'relative',
  },
  cornerImage: {
    position: 'absolute',
    top: 0,
    right: 0,
    maxWidth: 800,
    zIndex: theme.zIndex.drawer,
    display: 'flex',
    alignItems: 'center',
  },
  lowerCornerImage: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    maxWidth: 800,
    width: '80%',
    height: '70%',
    zIndex: theme.zIndex.drawer,
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    zIndex: theme.zIndex.drawer,
    height: '100vh',
    padding: theme.spacing(0, 10.5),
  },
  container: {
    height: '100%',
    marginTop: 0,
  },
  form: {
    height: '100%',
  },
  leftContent: {
    marginTop: 'auto',
    marginBottom: theme.spacing(6),
  },
  rightContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginBottom: theme.spacing(6),
  },
  outlinedInput: {
    padding: theme.spacing(2),
    [theme.breakpoints.up('xl')]: {
      padding: theme.spacing(2, 4),
    },
  },
  notchedOutline: {
    borderColor: theme.palette.primary.dark,
    borderWidth: 4,
    borderRadius: 40,
    [theme.breakpoints.up(1440)]: {
      borderWidth: 6,
      borderRadius: 60,
    },
    [theme.breakpoints.up('xl')]: {
      borderWidth: 8,
      borderRadius: 80,
    },
  },
  notchedOutlineFocused: {},
  textField: {
    color: theme.palette.primary.dark,
    borderColor: theme.palette.primary.dark,
    margin: theme.spacing(1, 0),
    [theme.breakpoints.up(1440)]: {
      fontSize: theme.typography.h4.fontSize,
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: theme.typography.h3.fontSize,
    },
    '&$notchedOutlineFocused $notchedOutline': {
      borderColor: theme.palette.primary.dark,
      [theme.breakpoints.up('xl')]: {
        borderWidth: 6,
      },
    },
    '&:hover $notchedOutline': {
      borderColor: theme.palette.primary.dark,
    },
    '&$outlinedInputError $notchedOutline': {
      borderColor: theme.palette.secondary.dark,
    },
  },
  outlinedInputError: {
    color: theme.palette.secondary.main,
  },
  alreadyRegisteredLink: {
    color: theme.palette.primary.dark,
    textDecoration: 'underline',
    marginBottom: theme.spacing(6),
  },
  alreadyRegistered: {
    [theme.breakpoints.up(1440)]: {
      fontSize: theme.typography.h3.fontSize,
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: theme.typography.h2.fontSize,
    },
  },
  GDPRInfo: {
    color: theme.palette.primary.main,
    [theme.breakpoints.up(1440)]: {
      fontSize: theme.typography.h5.fontSize,
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: theme.typography.h6.fontSize,
    },
  },
  submitButton: {
    color: theme.palette.common.white,
    borderRadius: 30,
    backgroundColor: theme.palette.primary.dark,
    width: '100%',
    margin: theme.spacing(1, 0),
    [theme.breakpoints.up(1440)]: {
      borderRadius: 60,
    },
    [theme.breakpoints.up('xl')]: {
      borderRadius: 80,
      minWidth: 400,
    },
  },
  submitButtonText: {
    [theme.breakpoints.up(1440)]: {
      fontSize: theme.typography.h5.fontSize,
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: theme.typography.h4.fontSize,
    },
  },
  GDPRLink: {
    color: theme.palette.primary.main,
    textDecoration: 'underline',
  },
  registerSuccessContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  registerSuccess: {
    color: theme.palette.primary.dark,
    [theme.breakpoints.up(1440)]: {
      fontSize: theme.typography.h3.fontSize,
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: theme.typography.h2.fontSize,
    },
  },
}));

export default useStyles;

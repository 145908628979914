export const REQUEST_GET_PAGE_CONTENT = 'REQUEST_GET_PAGE_CONTENT';
export const GET_PAGE_CONTENT_SUCCESS = 'GET_PAGE_CONTENT_SUCCESS';
export const GET_PAGE_CONTENT_ERROR = 'GET_PAGE_CONTENT_ERROR';

export const REQUEST_GET_PAGE_CHILDREN_MENUS = 'REQUEST_GET_PAGE_CHILDREN_MENUS';
export const GET_PAGE_CHILDREN_MENUS_SUCCESS = 'GET_PAGE_CHILDREN_MENUS_SUCCESS';
export const GET_PAGE_CHILDREN_MENUS_ERROR = 'GET_PAGE_CHILDREN_MENUS_ERROR';

export const REQUEST_GET_PAGE_PARENT = 'REQUEST_GET_PAGE_PARENT';
export const GET_PAGE_PARENT_SUCCESS = 'GET_PAGE_PARENT_SUCCESS';
export const GET_PAGE_PARENT_ERROR = 'GET_PAGE_PARENT_ERROR';

export const REQUEST_GET_FOOTER_CONTENT = 'REQUEST_GET_FOOTER_CONTENT';
export const GET_FOOTER_CONTENT_SUCCESS = 'GET_FOOTER_CONTENT_SUCCESS';
export const GET_FOOTER_CONTENT_ERROR = 'GET_FOOTER_CONTENT_ERROR';

export const CLEAR_ERRORS = 'CLEAR_ERRORS';

/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { Box, Container, IconButton, Link, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import Image from '../Image/Image';
import Header from '../Header/Header';
//import useStyles from './BalesetiFirstLevel.styles';
//import FirstLevelMobile from './FirstLevelMobile';
import Balesetimainmenu from '../balesetimainmenu/balesetimainmenu';
import { getMenu } from '../../redux/actions/balesetpage';
import { replaceVideoTag } from '../../helpers/utils';
import BalesetiBackButton from '../balesetiBackButton/BalesetiBackButton';
import BalesetiMobileMenu from '../balesetiMobileMenu/BalesetiMobileMenu';
import './BalesetiFirstLevel.css';

const BalesetiFirstLevel = (props) => {
  const { menuItems = [], pageType = 'baleseti', language } = props;
  const history = useHistory();
  const [selectedPage, setSelectedPage] = useState('gyermekeknek');
  //const classes = useStyles({ pageType, selectedPage });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.md));
  const altText = pageType === 'baleseti' ? 'gyermekbaleseti' : 'gyermeksürgősségi';

  const handleClick = () => history.goBack();
  const handleMouseOver = selected => setSelectedPage(selected.toLowerCase());

  //if (isMobile) return <FirstLevelMobile {...props} />;

  useEffect(() => {    
    props.getMenu();     
  }, []);
  
  if(!isMobile){
    return (        
      <div>
        <Balesetimainmenu/>
        <div className="FirstLevelmainImageContainer">
          <img className="FirstLevelmainImage" src="/images/Montazs_big.jpg" alt={altText}/>
        </div>      
      <h1 className="FirstLevelMessage">Minden súlyos gyermekbaleset megelőzhető!</h1>
      
      <div className="FirstLevelboxRow">
      {props.Intros.map(Intro => (
            <div className="FirstLevelBox">
            <img className="FirstLevelBoxImage" src={(Intro._embedded['wp:featuredmedia'] != undefined ? Intro._embedded['wp:featuredmedia'][0].source_url : '')}/>
            <h1 className="FirstLevelBoxTitle">{Intro.nameHu}</h1>
            <p className="FirstLevelBoxContent"  dangerouslySetInnerHTML={{__html: replaceVideoTag(Intro.excerpt.rendered)}}></p>
            <a className="FirstLevelBoxLink" href={Intro.link}>Tovább</a>
            </div>
          ))}
        </div>  
        <BalesetiBackButton targetPath="/"/>   
      </div>
    );
  }
  else
  {
    return (        
      <div>   
          <BalesetiMobileMenu/>  
        {/* <div className="FirstLevelmainImageContainerMobil">
          <img className="FirstLevelmainImageMobil" src="/images/Montazs_big.jpg" alt={altText}/>
        </div>       */}
      <h1 className="FirstLevelMessageMobil">Minden súlyos gyermekbaleset megelőzhető!</h1>
      
      <div className="FirstLevelboxRowMobil">
      {props.Intros.map(Intro => (
            <div className="FirstLevelBoxMobil">
            <img className="FirstLevelBoxImageMobil" src={(Intro._embedded['wp:featuredmedia'] != undefined ? Intro._embedded['wp:featuredmedia'][0].source_url : '')}/>
            <h1 className="FirstLevelBoxTitleMobil">{Intro.nameHu}</h1>
            <p className="FirstLevelBoxContentMobil"  dangerouslySetInnerHTML={{__html: replaceVideoTag(Intro.excerpt.rendered)}}></p>
            <a className="FirstLevelBoxLinkMobil" href={Intro.link}>Tovább</a>            
            <hr className="FirstLevelHRMobile"/>
            </div>            
          ))}
        </div>  
        <BalesetiBackButton targetPath="/"/>   
      </div>
    );
  }
       
};

//BalesetiFirstLevel.propTypes = {
//  menuItems: PropTypes.array.isRequired,
//  pageType: PropTypes.string.isRequired,
//  language: PropTypes.string,
//};

const mapStateToProps = ({ balesetipageReducer, languageReducer, userReducer }, ownProps) => {
  return {    
    Intros: balesetipageReducer.menuItems
  }
}

const mapDispatchToProps = () => {
  return {
    getMenu
  }
}

export default connect(mapStateToProps, mapDispatchToProps())(BalesetiFirstLevel);
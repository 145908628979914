export const REQUEST_GET_SESSION = 'REQUEST_GET_SESSION';
export const GET_SESSION_SUCCESS = 'GET_SESSION_SUCCESS';
export const GET_SESSION_ERROR = 'GET_SESSION_ERROR';

export const REQUEST_CHECK_SESSION = 'REQUEST_CHECK_SESSION';
export const GET_SESSION_CHECK_SUCCESS = 'GET_SESSION_CHECK_SUCCESS';
export const GET_SESSION_CHECK_ERROR = 'GET_SESSION_CHECK_ERROR';

export const SET_HAS_SESSION_FROM_COOKIE = 'SET_HAS_SESSION_FROM_COOKIE';

export const CLEAR_ERRORS = 'CLEAR_ERRORS';

/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
//import PropTypes from 'prop-types';
//import { connect } from 'react-redux';
//import { Link as RouterLink, useHistory } from 'react-router-dom';
import { useMediaQuery, useTheme } from '@material-ui/core';
//import Image from '../Image/Image';
//import Header from '../Header/Header';
//import useStyles from './balesetimainmenu.styles';
//import balesetimainmenuMobile from './balesetimainmenuMobile';
//import { getPageContent } from '../../redux/actions/page';
//import { getMenu } from '../../redux/actions/balesetpage';
import './BalesetiBackButton.css';

const BalesetiBackButton = (props) => {  
  const history = useHistory();
  const path = props.targetPath;
  const GoBack = () => {    
    history.push(path);  
    history.go();  
  }

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.md));
  if(!isMobile)
  {
    return (        
      <div className="BalesetiBack">
         <p className="BackLink" onClick={GoBack}>Vissza</p>
        </div>
    );      
  }
  else
  {
    return (        
      <div className="BalesetiBackMobile">
         <p className="BackLinkMobile" onClick={GoBack}>Vissza</p>
        </div>
    );      
  }
  
};

export default BalesetiBackButton;

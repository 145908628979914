/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { Box, Container, IconButton, Link, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import Image from '../Image/Image';
import Header from '../Header/Header';

import Balesetimainmenu from '../balesetimainmenu/balesetimainmenu';
import { SearchBalesetiPageContent } from '../../redux/actions/balesetpage';
import { replaceVideoTag } from '../../helpers/utils';

import './Search.css';

const Search = (props) => {
  
  const history = useHistory();
  const [selectedPage, setSelectedPage] = useState('gyermekeknek');
  
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.md));  

  const handleClick = () => history.goBack();
  const handleMouseOver = selected => setSelectedPage(selected.toLowerCase());
  
  const [searchTerm, setValue] = useState('');

  const Search = () => {    
    //setValue(event.target.value);
    history.push(props.data.searchRoute+searchTerm);  
    history.go(); 
  }

  const handleChange = event => {
   setValue(event.target.value);   
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      Search();
    }
  }

  useEffect(() => {    
    (props.data.currentSearchTerm == 'kereses' ? '' : props.SearchBalesetiPageContent(props.data.currentSearchTerm));    
  }, []);

  
        if(!isMobile){
          return (        
            <div>              
              <div class="searchbox">
                Keresőszó:<input type="text" class="searchInput" onKeyDown={handleKeyDown} value={searchTerm} onChange={handleChange}/><button type="button" onClick={Search}>keresés</button>
                
                  <div class="results"> 
                  {props.searchResult != null ? 
                  <div></div>           
                  :
                  <div></div>
                  }                    
                  {props.searchResult?.map((res, index) => (
                  <div><a href={res.link}>{index+1}. {res.nameHu}</a>                  
                  <div dangerouslySetInnerHTML={{ __html: replaceVideoTag(res.excerpt.rendered) }}></div>
                  </div>                  
                  ))
                  }
                      
                
              </div>
              
            </div>
            </div>
          );
        }
        else if(props.searchResult !== null)
        {
          return (        
            <div>              
              <div class="searchboxMobil">
                Keresőszó:<input type="text" class="searchInputMobil" onKeyDown={handleKeyDown} value={searchTerm} onChange={handleChange}/><button type="button" value={props.startedSearch}  onClick={Search}>keresés</button>
                
                  <div class="resultsMobil"> 
                  {props.searchResult != null ? 
                  <div></div>           
                  :
                  <div></div>
                  }                    
                  {props.searchResult?.map((res, index) => (
                  <div><a href={res.link}>{index+1}. {res.nameHu}</a>                  
                  <div dangerouslySetInnerHTML={{ __html: replaceVideoTag(res.excerpt.rendered) }}></div>
                  </div>                  
                  ))
                  }
                      
                
              </div>
              
            </div>
            </div>
          );
        }            
      
      return (<div></div>);
};

Search.propTypes = {
  searchResult: PropTypes.array, 
  searchTerm: PropTypes.string,
};

const mapStateToProps = ({ balesetipageReducer, languageReducer, userReducer }, ownProps) => {
  return {    
    searchResult: balesetipageReducer.searchResult,
    searchTerm: '',
    startedSearch: false,
  }
}

const mapDispatchToProps = () => {
  return {
    SearchBalesetiPageContent
  }
}

export default connect(mapStateToProps, mapDispatchToProps())(Search);
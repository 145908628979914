import { connect } from 'react-redux';
import Register from '../components/Register/Register';
import toggleLanguage from '../redux/actions/language';
import { register } from '../redux/actions/user';

const mapStateToProps = ({ userReducer, languageReducer }) => ({
  isFetching: userReducer.isFetching,
  errorMessage: userReducer.errorMessage,
  registerSuccess: userReducer.registerSuccess,
  userIsLoggedIn: userReducer.userIsLoggedIn,
  language: languageReducer.language,
});

const mapDispatchToProps = dispatch => ({
  register: (
    name,
    email,
    password,
    qualificationType,
    workplace,
  ) => dispatch(register(
    name,
    email,
    password,
    qualificationType,
    workplace,
  )),
  toggleLanguage: lang => dispatch(toggleLanguage(lang)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Register);

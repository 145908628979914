/* eslint-disable */
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  //Accident
  mainImageContainer: {    
    maxWidth: '100%',
    overflow: 'hidden'
  },
  mainImage: {
     height: '200px',
     width: '50%'
  }, 
  MenuContainer: {
    height: '100px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'   
  },
  upperMenuLink: {
    color: 'rgba(0,0,0,0.6)',
    fontWeight: '600',
    fontSize: '16px',
    paddingLeft: '30px',
    marginTop: '20px'
 },
 Logo: {
  height: '40px'
 }, 
 Message: {
  fontWeight: '600',
  textTransform: 'uppercase',
  fontSize: '36px',
  color: '#4d7d97',
  letterSpacing: '4px',
  textAlign: 'center',
 },
 boxRow: {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'  
 },
 Box: {
  height: '430px',
  width: '300px',  
  marginLeft: '50px',
  marginBottom: '30px'
 },
 BoxImage: {
   width: '100%'
 },
 BoxTitle: {
  fontWeight: '700',
  fontVariant: 'small-caps',
  fontSize: '30px',
  lineHeight: '1.5em',
  textAlign: 'left',
 },
 BoxContent: {
  fontSize: '14px',
  color: '#666',
  lineHeight: '1.7em'
 },
 search: {
  width: '30px',
  height: '30px',
  marginLeft: '50px',
  cursor: 'pointer',
 }  
}));


export default useStyles;

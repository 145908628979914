import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: '100vh',
    position: 'relative',
  },
  cornerImage: {
    position: 'absolute',
    top: 0,
    right: 0,
    maxWidth: 800,
    zIndex: theme.zIndex.drawer,
    display: 'flex',
    alignItems: 'center',
  },
  lowerCornerImage: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    maxWidth: 800,
    width: '80%',
    height: '70%',
    zIndex: theme.zIndex.drawer,
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    zIndex: theme.zIndex.drawer,
    minHeight: '100vh',
    padding: theme.spacing(0, 10.5),
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 'auto',
    marginBottom: theme.spacing(10),
    maxWidth: 400,
    [theme.breakpoints.up(1440)]: {
      maxWidth: 700,
    },
    [theme.breakpoints.up('xl')]: {
      maxWidth: 1000,
    },
  },
  notRegisteredLink: {
    color: theme.palette.primary.dark,
    marginBottom: theme.spacing(6),
    textDecoration: 'underline',
    [theme.breakpoints.up('xl')]: {
      marginBottom: theme.spacing(10),
    },
  },
  notRegistered: {
    [theme.breakpoints.up(1440)]: {
      fontSize: theme.typography.h4.fontSize,
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: theme.typography.h3.fontSize,
    },
  },
  errorMessage: {
    color: theme.palette.secondary.dark,
    [theme.breakpoints.up(1440)]: {
      fontSize: theme.typography.h5.fontSize,
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: theme.typography.h6.fontSize,
    },
  },
  outlinedInput: {
    padding: theme.spacing(2),
    [theme.breakpoints.up('xl')]: {
      padding: theme.spacing(2, 4),
    },
  },
  notchedOutline: {
    borderColor: theme.palette.primary.dark,
    borderWidth: 4,
    borderRadius: 40,
    [theme.breakpoints.up(1440)]: {
      borderWidth: 6,
      borderRadius: 60,
    },
    [theme.breakpoints.up('xl')]: {
      borderWidth: 8,
      borderRadius: 80,
    },
  },
  notchedOutlineFocused: {},
  textField: {
    color: theme.palette.primary.dark,
    borderColor: theme.palette.primary.dark,
    margin: theme.spacing(1, 0),
    [theme.breakpoints.up(1440)]: {
      fontSize: theme.typography.h4.fontSize,
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: theme.typography.h3.fontSize,
    },
    '&$notchedOutlineFocused $notchedOutline': {
      borderColor: theme.palette.primary.dark,
      [theme.breakpoints.up('xl')]: {
        borderWidth: 6,
      },
    },
    '&:hover $notchedOutline': {
      borderColor: theme.palette.primary.dark,
    },
    '&$outlinedInputError $notchedOutline': {
      borderColor: theme.palette.secondary.dark,
    },
  },
  outlinedInputError: {
    color: theme.palette.secondary.main,
  },
  buttonWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  submitButton: {
    color: theme.palette.common.white,
    borderRadius: 30,
    backgroundColor: theme.palette.primary.dark,
    minWidth: 200,
    [theme.breakpoints.up(1440)]: {
      padding: theme.spacing(1.5),
      borderRadius: 60,
      minWidth: 275,
    },
    [theme.breakpoints.up('xl')]: {
      padding: theme.spacing(2),
      borderRadius: 80,
      minWidth: 350,
    },
  },
  submitButtonText: {
    [theme.breakpoints.up(1440)]: {
      fontSize: theme.typography.h5.fontSize,
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: theme.typography.h4.fontSize,
    },
  },
  goBackButton: {
    marginTop: theme.spacing(1),
    marginRight: 'auto',
    color: theme.palette.primary.dark,
    borderColor: theme.palette.primary.dark,
    borderRadius: 30,
    borderWidth: 2,
    width: 135,
    padding: theme.spacing(0.5),
    [theme.breakpoints.up(1440)]: {
      width: 150,
      padding: theme.spacing(0.5),
      borderRadius: 30,
      borderWidth: 4,
    },
    [theme.breakpoints.up('xl')]: {
      width: 200,
      padding: theme.spacing(1),
      borderRadius: 40,
      borderWidth: 6,
    },
  },
  goBackButtonText: {
    fontSize: theme.typography.h6.fontSize,
    [theme.breakpoints.up(1440)]: {
      fontSize: theme.typography.h5.fontSize,
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: theme.typography.h4.fontSize,
    },
  },
}));

export default useStyles;

/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { Box, Container, IconButton, Link, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import Image from '../Image/Image';
import Header from '../Header/Header';
//import useStyles from './BalesetiThirdLevel.styles';
//import FirstLevelMobile from './FirstLevelMobile';
import Balesetimainmenu from '../balesetimainmenu/balesetimainmenu';
import { GetBalesetiPageContent } from '../../redux/actions/balesetpage';
import { replaceVideoTag } from '../../helpers/utils';
import BalesetiBackButton from '../balesetiBackButton/BalesetiBackButton';
import './BalesetiThirdLevel.css';
import BalesetiMobileMenu from '../balesetiMobileMenu/BalesetiMobileMenu';

const BalesetiThirdLevel = (props) => {
  const { menuItems = [], pageType = 'baleseti', language } = props;
  const history = useHistory();
  const [selectedPage, setSelectedPage] = useState('gyermekeknek');

  const path = location.pathname.split('/').filter(path => path != '');
  const backpath = "/" + path[0] + "/" + path[1];
  //const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.md));
  const altText = pageType === 'baleseti' ? 'gyermekbaleseti' : 'gyermeksürgősségi';

  if (path[1] == 'baleseti-szakmai' && props.userIsLoggedIn != null && !props.userIsLoggedIn) {
    history.push({
      pathname: '/login',
      state: { redirectPath: location.pathname },
    });
  }

  //const handleClick = () => history.goBack();
  const handleMouseOver = selected => setSelectedPage(selected.toLowerCase());

  useEffect(() => {
    props.GetBalesetiPageContent(location.pathname);
    //props.getSubMenus(556);     
  }, []);

  //const properties = props;
  if (props.balesetiPageContent !== undefined && !isMobile) {
    return (

      <div>
        <Balesetimainmenu />
        <hr></hr>
        <div className="ArticleContainer">
          <h1>{props.balesetiPageContent.meta._hu_post_title}</h1>
          <p>Szerző: {(props.balesetiPageContent._embedded.author !== undefined ? props.balesetiPageContent._embedded.author[0].name : '')} | {props.balesetiPageContent.date.substr(0, 10).replaceAll("-", ".")}</p>
          <img className="ArticleFeaturedImage" src={(props.balesetiPageContent._embedded['wp:featuredmedia'] !== undefined ? props.balesetiPageContent._embedded['wp:featuredmedia'][0].source_url : '')} />
          <div dangerouslySetInnerHTML={{ __html: replaceVideoTag(props.balesetiPageContent.content.rendered) }}></div>

        </div>
        <BalesetiBackButton targetPath={backpath} />
      </div>
    );
  }
  else if (props.balesetiPageContent !== undefined && isMobile) {
    return (

      <div>
        <BalesetiMobileMenu />
        <div className="ArticleContainerMobil">
          <h1>{props.balesetiPageContent.meta._hu_post_title}</h1>
          <p className="ArticleMetaMobil">Szerző: {(props.balesetiPageContent._embedded.author !== undefined ? props.balesetiPageContent._embedded.author[0].name : '')} | {props.balesetiPageContent.date.substr(0, 10).replaceAll("-", ".")}</p>
          <img className="ArticleFeaturedImageMobil" src={(props.balesetiPageContent._embedded['wp:featuredmedia'] !== undefined ? props.balesetiPageContent._embedded['wp:featuredmedia'][0].source_url : '')} />
          <div className="ArticleContent" dangerouslySetInnerHTML={{ __html: replaceVideoTag(props.balesetiPageContent.content.rendered) }}></div>

        </div>
        <BalesetiBackButton targetPath={backpath} />
      </div>
    );
  }
  return (<div></div>);
};

//BalesetiThirdLevel.propTypes = {
//  balesetiPageContent: PropTypes.string.isRequired
//};

const mapStateToProps = ({ balesetipageReducer, languageReducer, userReducer }, ownProps) => {
  return {
    balesetiPageContent: balesetipageReducer.balesetiPageContent,
    userIsLoggedIn: userReducer.userIsLoggedIn
  }
}

const mapDispatchToProps = () => {
  return {
    GetBalesetiPageContent
  }
}

export default connect(mapStateToProps, mapDispatchToProps())(BalesetiThirdLevel);

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Container, useMediaQuery, useTheme } from '@material-ui/core';
import Image from '../Image/Image';
import Content from './Content/Content';
import ContentMobile from './Content/ContentMobile';
import Footer from './Footer/Footer';
import FooterMobile from './Footer/FooterMobile';
import useStyles from './Home.styles';

const Home = ({
  isSessionFetching,
  hasSession,
  isFetching,
  errorMessage,
  getFooterContent,
  footer,
  userIsLoggedIn,
  language,
  toggleLanguage,
  userName,
}) => {
  const isFooterReady = !isSessionFetching && hasSession && !errorMessage && footer && !isFetching;
  const isFooterLoading = isSessionFetching || isFetching;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.md));

  useEffect(() => {
    if (!footer && hasSession) {
      getFooterContent();
    }
  }, [footer, getFooterContent, hasSession]);

  const contentProps = { userIsLoggedIn, userName };
  const contentMobileProps = { language, toggleLanguage };

  const footerProps = {
    data: footer,
    language,
    toggleLanguage,
    isLoading: isFooterLoading,
    isReady: isFooterReady,
  };

  const { toggleLanguage: _toggleLanguage, ...footerMobileProps } = footerProps;

  const classes = useStyles({ isMobile });
  return (
    <Container className={classes.root} maxWidth={false} disableGutters>
      <Image className={classes.cornerImage} pictureClassName={classes.cornerImage} src="home_corner" alt="home_corner" />
      {isMobile ? <ContentMobile {...contentProps} {...contentMobileProps} /> : <Content {...contentProps} />}
      {isMobile ? <FooterMobile {...footerMobileProps} /> : <Footer {...footerProps} />}
    </Container>
  );
};

Home.propTypes = {
  language: PropTypes.string,
  toggleLanguage: PropTypes.func,
  isSessionFetching: PropTypes.bool,
  hasSession: PropTypes.bool,
  isFetching: PropTypes.bool,
  errorMessage: PropTypes.string,
  getFooterContent: PropTypes.func,
  footer: PropTypes.object,
  userIsLoggedIn: PropTypes.bool.isRequired,
  userName: PropTypes.string,
};

export default Home;

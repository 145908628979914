import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    paddingTop: 100,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    height: 'calc(100vh - 63px)',
  },
  title: {
    textAlign: 'start',
    fontWeight: 900,
    color: theme.palette.secondary.dark,
    textTransform: 'uppercase',
    wordBreak: 'break-word',
  },
  linkContainer: {
    display: 'flex',
    margin: theme.spacing(2, 0),
    flexWrap: 'wrap',
  },
  link: {
    fontWeight: 600,
    fontSize: theme.typography.h5.fontSize,
    lineHeight: theme.typography.h5.lineHeight,
    margin: 0,
  },
  linkAccident: {
    color: theme.palette.secondary.main,
    textDecoration: 'underline',
    '&:active': {
      color: theme.palette.primary.dark,
    },
  },
  linkSOS: {
    color: theme.palette.secondary.main,
    textDecoration: 'underline',
    '&:hover': {
      color: theme.palette.secondary.dark,
    },
  },
  linkSeparator: {
    color: theme.palette.secondary.main,
    margin: theme.spacing(0, 1),
  },
  imageWrapper: {
    height: '100%',
    position: 'relative',
  },
  image: {
    position: 'absolute',
    bottom: 0,
    maxWidth: '100%',
    maxHeight: '100%',
  },
}));

export default useStyles;

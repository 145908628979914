import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { Container, FormGroup, FormControl, OutlinedInput, Grid, Button, Typography, Link, useTheme, useMediaQuery } from '@material-ui/core';
import { useTranslation, Trans } from 'react-i18next';
import * as yup from 'yup';
import { useFormik } from 'formik';
import Image from '../Image/Image';
import Header from '../Header/Header';
import Loading from '../Loading/Loading';
import RegisterMobile from './RegisterMobile';
import useStyles from './Register.styles';

const handleRegister = (
  name,
  email,
  password,
  qualificationType,
  workplace,
  register,
) => {
  register(
    name,
    email,
    password,
    qualificationType,
    workplace,
  );
};

const validationSchema = yup.object({
  name: yup
    .string('Enter your name')
    .required('Name is required'),
  email: yup
    .string('Enter your email')
    .email('Enter a valid email')
    .required('Email is required'),
  qualificationType: yup
    .string('Enter your qualification type')
    .required('Qualification type is required'),
  workplace: yup
    .string('Enter your workplace')
    .required('Workplace is required'),
  password: yup
    .string('Enter your password')
    .required('Password is required'),
  passwordAgain: yup.string().when('password', {
    is: val => (val && val.length),
    then: yup.string().oneOf(
      [yup.ref('password')],
      'Both password need to be the same',
    ),
  }).required('Password confirmation is required'),
});

const Register = (props) => {
  const { register, isFetching, errorMessage, registerSuccess, userIsLoggedIn } = props;
  const { t } = useTranslation(['register']);
  const rightNav = { title: t('title'), link: '/' };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.md));
  const classes = useStyles();

  useEffect(() => {
    if (registerSuccess) {
      setTimeout(() => {
        window.location.href = '/login';
      }, 3000);
    }
    if (userIsLoggedIn) window.location.href = '/';
  }, [registerSuccess, userIsLoggedIn]);

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      qualificationType: '',
      workplace: '',
      password: '',
      passwordAgain: '',
    },
    validationSchema,
    onSubmit: (values) => {
      const {
        name,
        email,
        password,
        qualificationType,
        workplace,
      } = values;
      handleRegister(name, email, password, qualificationType, workplace, register);
    },
  });

  if (isMobile) return <RegisterMobile {...props} handleRegister={handleRegister} validationSchema={validationSchema} />;
  if (isFetching) return <Loading />;

  const controls = ['name', 'email', 'qualificationType', 'workplace', 'password', 'passwordAgain'];

  return (
    <Container className={classes.root} maxWidth={false} disableGutters>
      <Image className={classes.cornerImage} pictureClassName={classes.cornerImage} src="upper_corner_baleseti" alt="upper corner" />
      <Image className={classes.lowerCornerImage} pictureClassName={classes.lowerCornerImage} src="lower_corner" alt="lower corner" />
      <Container className={classes.contentWrapper} maxWidth={false}>
        <Header pageType="register" rightNav={rightNav} />
        <form className={classes.form} onSubmit={formik.handleSubmit}>
          <Grid container spacing={8} className={classes.container}>
            {registerSuccess
              ? (
                <Grid item md={7} className={classes.registerSuccessContainer}>
                  <Typography className={classes.registerSuccess} variant="h4">{t('success')}</Typography>
                </Grid>
              )
              : (
                <>
                  <Grid item md={7} className={classes.leftContent}>
                    <FormGroup>
                      {controls.map(control => (
                        <FormControl key={control} variant="outlined">
                          <OutlinedInput
                            fullWidth
                            id={control}
                            name={control}
                            type={control.includes('password') ? 'password' : 'text'}
                            placeholder={t(control)}
                            value={formik.values[control]}
                            onChange={formik.handleChange}
                            error={formik.touched[control] && formik.errors[control]}
                            classes={{
                              input: classes.outlinedInput,
                              notchedOutline: classes.notchedOutline,
                              focused: classes.notchedOutlineFocused,
                              error: classes.outlinedInputError,
                            }}
                            className={classes.textField}
                          />
                        </FormControl>
                      ))}
                    </FormGroup>
                  </Grid>
                  <Grid item md={5} className={classes.rightContent}>
                    <Link className={classes.alreadyRegisteredLink} component={RouterLink} to="/login">
                      <Typography className={classes.alreadyRegistered} variant="h4">{t('alreadyRegistered')}</Typography>
                    </Link>
                    <Typography variant="subtitle1" className={classes.GDPRInfo}>
                      <Trans
                        i18nKey="register:GDPRInfo"
                        components={[<Link className={classes.GDPRLink} component={RouterLink} to="/login">link</Link>]}
                      />
                    </Typography>
                    <Button className={classes.submitButton} variant="contained" type="submit" disableElevation>
                      <Typography className={classes.submitButtonText} variant="h6">{t('submit')}</Typography>
                    </Button>
                  </Grid>
                </>
              )}
          </Grid>
          {!isFetching && errorMessage && (
            <div>{errorMessage}</div>
          )}
        </form>
      </Container>
    </Container>
  );
};

Register.propTypes = {
  register: PropTypes.func.isRequired,
  registerSuccess: PropTypes.bool.isRequired,
  userIsLoggedIn: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string,
};

export default Register;

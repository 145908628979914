export const wpStructureLevels = {
  firstLevel: 'first_level',
  secondLevel: 'second_level',
  thirdLevel: 'third_level',
};

export const wpDesigns = {
  emergency: 'surgossegi',
  accident: 'baleseti',
};

export const errorCodes = {
  ACCESS_DENIED: 'ACCESS_DENIED',
  SOMETHING_WENT_WRONG: 'SOMETHING_WENT_WRONG',
  NO_PAGE_DATA: 'NO_PAGE_DATA',
  REST_CANNOT_CREATE_USER: 'REST_CANNOT_CREATE_USER',
  USER_IS_EXISTING: 'USER_IS_EXISTING',
  UNAUTHORIZED: 'UNAUTHORIZED',
  BAD_REQUEST: 'BAD_REQUEST',
  UNKNOWN_API_ERROR: 'UNKNOWN_API_ERROR',
  UNKNOWN_ERROR: 'UNKNOWN_ERROR',
  INVALID_TOKEN: 'INVALID_TOKEN',
};

export const qualificationTypes = {
  ERETTSEGI: 'Érettségi',
  EGYETEM: 'Egyetem',
};

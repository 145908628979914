import React from 'react';
import PropTypes from 'prop-types';
import { Container } from '@material-ui/core';

import Image from '../../Image/Image';
import useStyles from './LowerLevelLayout.styles';

const LowerLevelLayout = ({ children, pageType = 'baleseti' }) => {
  const classes = useStyles({ pageType });
  const altText = pageType === 'baleseti' ? 'gyermekbaleseti' : 'gyermeksürgősségi';
  return (
    <Container className={classes.root} maxWidth={false} disableGutters>
      <Image className={classes.cornerImage} pictureClassName={classes.cornerImage} src={`upper_corner_${pageType}`} alt={altText} />
      <Image className={classes.lowerCornerImage} pictureClassName={classes.lowerCornerImage} src="lower_corner" alt={altText} />
      <Container className={classes.content} maxWidth={false}>
        {children}
      </Container>
    </Container>
  );
};

LowerLevelLayout.propTypes = {
  pageType: PropTypes.string.isRequired,
  children: PropTypes.node,
};

export default LowerLevelLayout;

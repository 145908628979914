import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: '100vh',
    backgroundColor: props => (
      props.pageType === 'baleseti' ? theme.palette.primary.light : theme.palette.secondary.light
    ),
    position: 'relative',
  },
  cornerImage: {
    position: 'absolute',
    top: 0,
    right: 0,
    maxWidth: 800,
    zIndex: theme.zIndex.drawer,
    display: 'flex',
    alignItems: 'center',
  },
  lowerCornerImage: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    maxWidth: 800,
    maxHeight: 500,
    width: '80%',
    height: '70%',
    zIndex: theme.zIndex.drawer,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    zIndex: theme.zIndex.drawer,
    minHeight: '100vh',
    padding: theme.spacing(0, 10.5),
  },
}));

export default useStyles;

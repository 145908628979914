import axios from 'axios';
import Cookies from 'js-cookie';
import { errorCodes } from '../constants/constants';

export const getCookies = () => {
  const token = Cookies.get('_ogysebpSession');
  const permission = Cookies.get('_ogysebpPermission');
  const userName = Cookies.get('_ogysebpUserName');
  return { token, permission, userName };
};

const getHeaders = () => {
  const { token } = getCookies();
  const headers = {};
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }
  return headers;
};

export const callApi = (options, APIPrefix) => axios({
  ...options,
  baseURL: APIPrefix,
  headers: getHeaders(),
});

export const removeCookies = () => {
  Cookies.remove('_ogysebpSession');
  Cookies.remove('_ogysebpPermission');
  Cookies.remove('_ogysebpUserName');
  Cookies.remove('wordpress_test_cookie');
};

export const logOut = () => {
  removeCookies();
  window.location.href = '/';
};

const getErrorMessage = (error) => {
  const { response = {} } = error;
  if (response && Object.keys(response).length > 0) {
    const { data = {}, status } = response;
    const { code = '' } = data;
    if (code === 'existing_user_login') return errorCodes.USER_IS_EXISTING;
    if (code === 'access_denied') return errorCodes.ACCESS_DENIED;
    if (code === 'jwt_auth_invalid_token') return errorCodes.INVALID_TOKEN;
    if (status === 401) return errorCodes.UNAUTHORIZED;
    if (status === 400) return errorCodes.BAD_REQUEST;
    if (status === 500) return errorCodes.UNKNOWN_API_ERROR;
  }
  return errorCodes.UNKNOWN_ERROR;
};

export const handleErrors = (error) => {
  const errorMsg = getErrorMessage(error);
  if (errorMsg === errorCodes.INVALID_TOKEN) {
    removeCookies();
    window.location.reload();
  }
  return errorMsg;
};

const getYoutubeVideoID = (url = '') => {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  const match = url.match(regExp);

  return match && match[2].length === 11 ? match[2] : null;
};

const createVideoIframe = (url = '') => {
  const videoId = getYoutubeVideoID(url);

  return `<iframe 
    loading="lazy" 
    title="Youtube video" 
    width="750" 
    height="422" 
    src="https://www.youtube.com/embed/${videoId}" 
    frameborder="0" 
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
    allowfullscreen>
  </iframe>`;
};

const convertHTML = (str = '') => {
  const regex = /[&|<|>|"|']/g;
  const htmlString = str.replace(regex, (match) => {
    if (match === '&') {
      return '&amp;';
    } if (match === '<') {
      return '&lt;';
    } if (match === '>') {
      return '&gt;';
    } if (match === '"') {
      return '&quot;';
    }
    return '&apos;';
  });
  return htmlString;
};

const manipulateContent = (content, url, jsonRegex) => {
  const contentWithoutJson = content.replace(jsonRegex, '');
  const encodedURL = encodeURI(url);
  const htmlCodedURL = convertHTML(encodedURL);
  return contentWithoutJson.replace(
    htmlCodedURL,
    createVideoIframe(encodedURL),
  );
};

export const replaceVideoTag = (content = '') => {
  const jsonRegex = /\{(?:[^{}]|())*\}/gm;
  const jsonObjects = content.match(jsonRegex);
  if (!jsonObjects || jsonObjects.length === 0) return content;
  return jsonObjects.reduce((newContent, jsonObject) => {
    const { url = '' } = JSON.parse(jsonObject);
    if (!url || url === '') return content;
    return manipulateContent(newContent, url, jsonRegex);
  }, content);
};

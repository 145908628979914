import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import {
  Box,
  Container,
  Link, Typography,
  FormGroup,
  FormControl,
  OutlinedInput,
  Button,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { useFormik } from 'formik';
import Image from '../Image/Image';
import Header from '../Header/Header';
import Loading from '../Loading/Loading';
import LoginMobile from './LoginMobile';
import useStyles from './Login.styles';

const handleLogin = (email, password, login) => {
  login(email, password);
};

const validationSchema = yup.object({
  email: yup
    .string('Enter your email')
    .email('Enter a valid email')
    .required('Email is required'),
  password: yup
    .string('Enter your password')
    .required('Password is required'),
});

const Login = (props) => {
  const { login, location = {}, userIsLoggedIn, errorMessage, isFetching } = props;
  const history = useHistory();
  const { t } = useTranslation(['login']);
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema,
    onSubmit: (values) => {
      const { email, password } = values;
      handleLogin(email, password, login);
    },
  });

  const handleBackClick = () => {
    history.go(-2);
  };

  const rightNav = { title: t('title'), link: '/' };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.md));
  const classes = useStyles();

  useEffect(() => {
    if (userIsLoggedIn && !isFetching && !errorMessage) {
      const { state = {} } = location;
      const { redirectPath = '/' } = state;
      window.location.href = redirectPath;
    }
  }, [userIsLoggedIn, isFetching, errorMessage, location]);

  if (isMobile) return <LoginMobile {...props} handleLogin={handleLogin} validationSchema={validationSchema} />;
  if (isFetching) return <Loading />;

  const controls = ['email', 'password'];

  return (
    <Container className={classes.root} maxWidth={false} disableGutters>
      <Image className={classes.cornerImage} pictureClassName={classes.cornerImage} src="upper_corner_baleseti" alt="upper corner" />
      <Image className={classes.lowerCornerImage} pictureClassName={classes.lowerCornerImage} src="lower_corner" alt="lower corner" />
      <Container className={classes.contentWrapper} maxWidth={false}>
        <Header pageType="login" rightNav={rightNav} />
        <Box className={classes.content}>
          <Link className={classes.notRegisteredLink} component={RouterLink} to="/register">
            <Typography className={classes.notRegistered} variant="h5">{t('notRegistered')}</Typography>
          </Link>
          <form onSubmit={formik.handleSubmit}>
            <FormGroup>
              {errorMessage && <Typography className={classes.errorMessage} variant="h6">{t('errorMessage')}</Typography>}
              {controls.map(control => (
                <FormControl key={control} variant="outlined">
                  <OutlinedInput
                    fullWidth
                    id={control}
                    name={control}
                    type={control}
                    placeholder={t(control)}
                    value={formik.values[control]}
                    onChange={formik.handleChange}
                    error={(formik.touched[control] && formik.errors[control]) || errorMessage}
                    classes={{
                      input: classes.outlinedInput,
                      notchedOutline: classes.notchedOutline,
                      focused: classes.notchedOutlineFocused,
                      error: classes.outlinedInputError,
                    }}
                    className={classes.textField}
                  />
                </FormControl>
              ))}
            </FormGroup>
            <Box className={classes.buttonWrapper}>
              <Button className={classes.submitButton} variant="contained" type="submit" disableElevation>
                <Typography className={classes.submitButtonText} variant="h6">{t('submit')}</Typography>
              </Button>
              <Button onClick={handleBackClick} className={classes.goBackButton} variant="outlined" disableElevation>
                <Typography className={classes.goBackButtonText} variant="h6">{t('back')}</Typography>
              </Button>
            </Box>
          </form>
        </Box>
      </Container>
    </Container>
  );
};

Login.propTypes = {
  login: PropTypes.func.isRequired,
  location: PropTypes.object,
  userIsLoggedIn: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string,
};

export default Login;

/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
//import { Link as RouterLink, useHistory } from 'react-router-dom';
//import { Box, Container, IconButton, Link, Typography, useMediaQuery, useTheme } from '@material-ui/core';
//import Image from '../Image/Image';
//import Header from '../Header/Header';
//import useStyles from './balesetimainmenu.styles';
//import balesetimainmenuMobile from './balesetimainmenuMobile';
//import { getPageContent } from '../../redux/actions/page';
import { getMenu } from '../../redux/actions/balesetpage';
import './BalesetiMobileMenu.css';


const Balesetimainmenumobile = (props) => {  
  //const classes = useStyles();

  useEffect(() => {    
    props.getMenu(35);     
  }, []);

  

  return (   
    <div>
    <section class="top-nav">
        <input id="menu-toggle" type="checkbox" />
        <label class='menu-button-container' for="menu-toggle">
        <div class='menu-button'></div>
        </label>
          <ul class="menu">
          {props.menuItems.map((menuItem,index) => (
        /*<li key={index}><a href={menuItem.link}>{menuItem.nameHu}</a>*/
        <li key={index}><a href={menuItem.link}>{menuItem.nameHu}</a>
        </li>               
        ))}
          </ul> 
          <a href="/balesetikereses"><img class="searchmobile" src="/images/Search_Icon.png"/></a>     
        </section> 
    </div>     
  );      
};

Balesetimainmenumobile.propTypes = {
  //menuItems: PropTypes.array.isRequired,
  //pageType: PropTypes.string.isRequired,
  //language: PropTypes.string,
};

const mapStateToProps = ({ balesetipageReducer, languageReducer, userReducer }, ownProps) => {
  return {    
    menuItems: balesetipageReducer.menuItems
  }
}

const mapDispatchToProps = () => {
  return {
    getMenu
  }
}

export default connect(mapStateToProps, mapDispatchToProps())(Balesetimainmenumobile);
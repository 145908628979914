import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { Container, Typography, Link, useMediaQuery, IconButton } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import Image from '../../Image/Image';
import useStyles from './Header.styles';
import MobileMenu from '../../MobileMenu/MobileMenu';
import { logOut } from '../../../helpers/utils';

const Header = ({ toggleLanguage, language, userIsLoggedIn, userName }) => {
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const classes = useStyles({ isMobile });
  const { t } = useTranslation(['menu']);
  const names = userName ? userName.split(' ') : [];
  const initials = names.map(name => name[0]);
  return (
    <Container className={classes.root} maxWidth={false} disableGutters>
      <Image className={classes.logo} pictureClassName={classes.logo} src="logo" alt="logo" />
      {isMobile ? <MobileMenu toggleLanguage={toggleLanguage} language={language} pageType="home" userIsLoggedIn={userIsLoggedIn} /> : (
        <Typography variant="subtitle2">
          <Link component={RouterLink} className={classes.link} to="/misszionk">
            {t('mission')}
          </Link>
          {/* <Link component={RouterLink} className={classes.link} to="/dokumentaciok">
            {t('documentations')}
          </Link> */}
          <Link component={RouterLink} className={classes.link} to="/kapcsolat">
            {t('contact')}
          </Link>
          {userIsLoggedIn && (
            <IconButton onClick={() => logOut()} className={classes.logoutButton} aria-label="logout" color="error">
              <Typography className={classes.initials} variant="body1">{initials}</Typography>
              <Typography className={classes.logoutText} variant="caption">{t('logout')}</Typography>
            </IconButton>
          )}
        </Typography>
      )}
    </Container>
  );
};

Header.propTypes = {
  language: PropTypes.string,
  toggleLanguage: PropTypes.func,
  userIsLoggedIn: PropTypes.bool,
  userName: PropTypes.string,
};

export default Header;

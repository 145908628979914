/* eslint-disable*/
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-return-assign */
import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Link, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Image from '../Image/Image';
import MobileMenu from '../MobileMenu/MobileMenu';
import useStyles from './Header.styles';

const Header = ({ pageType, rightNav, isMobile = false, language, toggleLanguage, userIsLoggedIn }) => {
  const { t } = useTranslation(['header']);
  const headerTitles = {
    baleseti: t('accidentTitle'),
    surgossegi: t('sosTitle'),
    login: t('loginTitle'),
    register: t('registerTitle'),
  };
  const assetPageType = pageType === 'surgossegi' ? 'surgossegi' : 'baleseti';

  const classes = useStyles({ pageType: assetPageType, isMobile });

  return (
    <Box className={classes.root}>
      <Box className={classes.headerLeft}>
        <Link className={classes.logoLink} component={RouterLink} to="/">
          <Image className={classes.logo} pictureClassName={classes.logo} src="logo" alt="logo" />          
        </Link>                
        {pageType && (
          <Box className={classes.pageTitleWrapper}>
            <Image className={classes.separator} pictureClassName={classes.separatorPicture} src={`separator_${assetPageType}`} alt="separator" />
            <Link className={classes.headerTitleLink} component={RouterLink} to={`/${pageType}`}>
              <Typography className={classes.headerTitle} variant={isMobile ? 'subtitle2' : 'h6'}>
                {headerTitles[pageType]}
              </Typography>
            </Link>
          </Box>
        )}
        {!isMobile ? (<div><a href={"/surgossegikereses?backurl="+location.pathname + '&term='}><img className={classes.search} src="/images/Search_Icon.png"/></a></div>) : <div></div>
        }
      </Box>
      {isMobile ? (
        <MobileMenu pageType={pageType} toggleLanguage={toggleLanguage} language={language} userIsLoggedIn={userIsLoggedIn} />
      ) : rightNav && (
        <Box className={classes.headerRight}>
          <Typography variant="h3">
            <Link component={RouterLink} className={classes.parentLink} to={rightNav.link}>
              <Image className={classes.backButton} src={`arrow_${assetPageType}`} alt="arrow" />
              {rightNav.title}
            </Link>
          </Typography>
        </Box>
      )}
    </Box>
  );
};

Header.propTypes = {
  pageType: PropTypes.string,
  rightNav: PropTypes.object,
  isMobile: PropTypes.bool,
  toggleLanguage: PropTypes.func,
  language: PropTypes.string,
  userIsLoggedIn: PropTypes.bool,
};

export default Header;

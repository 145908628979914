export const REQUEST_LOGIN = 'REQUEST_LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';

export const SET_HAS_USER_SESSION_FROM_COOKIE = 'SET_HAS_USER_SESSION_FROM_COOKIE';

export const REQUEST_REGISTER = 'REQUEST_REGISTER';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_ERROR = 'REGISTER_ERROR';

export const CLEAR_ERRORS = 'CLEAR_ERRORS';

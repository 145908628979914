import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { Container, FormGroup, FormControl, OutlinedInput, Box, Button, Typography, Link } from '@material-ui/core';
import { useTranslation, Trans } from 'react-i18next';
import { useFormik } from 'formik';

import Header from '../Header/Header';
import Loading from '../Loading/Loading';
import useStyles from './RegisterMobile.styles';

const RegisterMobile = ({
  register,
  isFetching,
  errorMessage,
  registerSuccess,
  userIsLoggedIn,
  validationSchema,
  handleRegister,
  language,
  toggleLanguage,
}) => {
  const { t } = useTranslation(['register']);

  const classes = useStyles();

  useEffect(() => {
    if (registerSuccess) {
      setTimeout(() => {
        window.location.href = '/login';
      }, 3000);
    }
    if (userIsLoggedIn) window.location.href = '/';
  }, [registerSuccess, userIsLoggedIn]);

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      qualificationType: '',
      workplace: '',
      password: '',
      passwordAgain: '',
    },
    validationSchema,
    onSubmit: (values) => {
      const {
        name,
        email,
        password,
        qualificationType,
        workplace,
      } = values;
      handleRegister(name, email, password, qualificationType, workplace, register);
    },
  });

  if (isFetching) return <Loading />;

  const controls = ['name', 'email', 'qualificationType', 'workplace', 'password', 'passwordAgain'];

  return (
    <Container className={classes.root} maxWidth="xl" disableGutters>
      <Container className={classes.contentWrapper} maxWidth="xl">
        <Header pageType="register" isMobile toggleLanguage={toggleLanguage} language={language} />
        <Box className={classes.content}>
          {registerSuccess
            ? (
              <Typography className={classes.registerSuccess} variant="h4">{t('success')}</Typography>
            )
            : (
              <>
                <Typography className={classes.title} variant="h5">{t('title')}</Typography>
                <form onSubmit={formik.handleSubmit}>
                  <FormGroup>
                    {controls.map(control => (
                      <FormControl key={control} variant="outlined">
                        <OutlinedInput
                          fullWidth
                          id={control}
                          name={control}
                          type={control.includes('password') ? 'password' : 'text'}
                          placeholder={t(control)}
                          value={formik.values[control]}
                          onChange={formik.handleChange}
                          error={formik.touched[control] && formik.errors[control]}
                          classes={{
                            input: classes.outlinedInput,
                            notchedOutline: classes.notchedOutline,
                            focused: classes.notchedOutlineFocused,
                            error: classes.outlinedInputError,
                          }}
                          className={classes.textField}
                        />
                      </FormControl>
                    ))}
                  </FormGroup>
                  <Typography variant="subtitle1" className={classes.GDPRInfo}>
                    <Trans
                      i18nKey="register:GDPRInfo"
                      components={[<Link className={classes.GDPRLink} component={RouterLink} to="/login">link</Link>]}
                    />
                  </Typography>
                  <Button className={classes.submitButton} variant="contained" type="submit" disableElevation>
                    <Typography variant="h6">{t('submit')}</Typography>
                  </Button>
                  {!isFetching && errorMessage && (
                    <div>{errorMessage}</div>
                  )}
                </form>
              </>
            )}
        </Box>
      </Container>
    </Container>
  );
};

RegisterMobile.propTypes = {
  register: PropTypes.func.isRequired,
  registerSuccess: PropTypes.bool.isRequired,
  userIsLoggedIn: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string,
  handleRegister: PropTypes.func,
  validationSchema: PropTypes.object,
  language: PropTypes.string,
  toggleLanguage: PropTypes.func,
};

export default RegisterMobile;

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Box, Container, Button, Typography } from '@material-ui/core';
import Image from '../Image/Image';
import Header from '../Header/Header';
import useStyles from './FirstLevelMobile.styles';

const FirstLevelMobile = (props) => {
  const { menuItems = [], pageType = 'baleseti', language, toggleLanguage, userIsLoggedIn } = props;
  const classes = useStyles({ pageType });

  return (
    <Container className={classes.root} maxWidth="xl" disableGutters>
      <Image className={classes.bgImage} src={`first_level_bg_${pageType}`} alt="background" />
      <Container className={classes.content} maxWidth="xl">
        <Header pageType={pageType} isMobile toggleLanguage={toggleLanguage} language={language} userIsLoggedIn={userIsLoggedIn} />
        <Box className={classes.links}>
          {menuItems.map(menuItem => (
            <Box key={menuItem.id}>
              <Link to={menuItem.link} className={classes.link}>
                <Button className={classes.linkButton} variant="outlined">
                  <Typography variant="h4" className={classes.linkText}>
                    {language === 'en' ? menuItem.nameEn : menuItem.nameHu}
                  </Typography>
                </Button>
              </Link>
            </Box>
          ))}
        </Box>
      </Container>
    </Container>
  );
};

FirstLevelMobile.propTypes = {
  menuItems: PropTypes.array.isRequired,
  pageType: PropTypes.string.isRequired,
  language: PropTypes.string,
  toggleLanguage: PropTypes.func,
  userIsLoggedIn: PropTypes.bool,
};

export default FirstLevelMobile;

/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, useTheme, useMediaQuery, Link } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { useHistory } from 'react-router';
import { replaceVideoTag } from '../../helpers/utils';
import Header from '../Header/Header';
import ThirdLevelMobile from './ThirdLevelMobile';
import useStyles from './ThirdLevel.styles';
import LowerLevelLayout from '../Layouts/LowerLevelLayout/LowerLevelLayout';
import Balesetimainmenu from '../balesetimainmenu/balesetimainmenu';
import './ThirdLevel.css';

const ThirdLevel = (props) => {
  const history = useHistory();
  
  const { currentPage = {}, parentPage = {}, pageType = 'baleseti', language, menuItems } = props;
  const { meta } = currentPage;
  const { _hu_post_title: titleHu, _hu_post_content: contentHu, _en_post_title: titleEn, _en_post_content: contentEn } = meta;
  const { link: parentLink, nameHu: parentTitleHu, nameEn: parentTitleEn } = parentPage;
  const rightNav = { title: language === 'en' ? parentTitleEn : parentTitleHu, link: parentLink };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.md));
  const classes = useStyles({ pageType });

  const path = location.pathname.split('/').filter(path => path != '');
  let backpath = '/';
  for (let index = 0; index < path.length - 1; index++) {
    backpath += path[index] + '/';
  }
  const handleClick = () => history.push(backpath);

  if (props.userIsLoggedIn != null && !props.userIsLoggedIn && location.pathname.indexOf('surgossegi/szakmai') > 0) {
    history.push({
      pathname: '/login',
      state: { redirectPath: location.pathname },
    });
  }

  if (isMobile) return <ThirdLevelMobile {...props} />;
  if (props.pageType == 'baleseti') {
    return (
      <div>
        <Balesetimainmenu />
        <hr></hr>
        <div className={classes.articleContainer}>
          <h1>{props.currentPage.meta._hu_post_title}</h1>
          {/* <p>Szerző: hordani | jan 30, 2022 | Serdülőknek | Nincsenek hozzászólások</p> */}
          {/* <img className={classes.articleImage} src="/images/best_headphones_lifestyle.jpg"/> */}
          <div class="entry-content" dangerouslySetInnerHTML={{ __html: replaceVideoTag(props.currentPage.content.rendered) }}>

          </div>
        </div>
      </div>
    );
  }
  else {
    return (
      <LowerLevelLayout pageType={pageType}>
        <Header pageType={pageType} rightNav={rightNav} />
        <Box className={classes.content}>
          <Typography className={classes.title} variant="h2">
            {language === 'en' ? titleEn : titleHu}
          </Typography>
          <Box>
            {menuItems.map(menuItem => (
              <Typography variant="subtitle2" key={menuItem.id}>
                <Link component={RouterLink} className={classes.link} to={menuItem.link}>
                  {language === 'en' ? menuItem.nameEn : menuItem.nameHu}
                </Link>
              </Typography>
            ))}
          </Box>
          <div className={classes.wpContent} dangerouslySetInnerHTML={{ __html: language === 'en' ? replaceVideoTag(contentEn) : replaceVideoTag(contentHu) }} />
        </Box>
      </LowerLevelLayout>
    );
  }
};

ThirdLevel.propTypes = {
  currentPage: PropTypes.object.isRequired,
  parentPage: PropTypes.object.isRequired,
  pageType: PropTypes.string.isRequired,
  language: PropTypes.string,
  menuItems: PropTypes.array,
};

export default ThirdLevel;

import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@material-ui/core/styles';
import { CssBaseline } from '@material-ui/core';
import ReactGA from 'react-ga';

import theme from './helpers/theme';
import './helpers/i18nInit';
import MainRouter from './components/MainRouter/MainRouter';

const TRACKING_ID = 'UA-242355587-1';
  ReactGA.initialize(TRACKING_ID);

const App = ({ store }) => (
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <MainRouter />
    </ThemeProvider>
  </Provider>
);

App.propTypes = {
  store: PropTypes.object.isRequired,
};

export default App;

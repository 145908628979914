/* eslint-disable */
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  //Accident
  mainImageContainer: {    
    maxWidth: '100%',
    height: '700px',
    overflow: 'hidden',
    backgroundImage: 'url("/images/serdulokHeader.png")',
    backgroundSize: 'cover',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'start' 
  }, 
  mainImageContent: {
    width: '25%',    
    color: 'white'
  },
  mainImageContentHeader: {
    fontSize: '50px'
  },
  mainImageContentBody: {
    fontSize: '18px',
    lineHeight: '28px'
  },
  MenuContainer: {
    height: '100px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'   
  },  
  upperMenuLink: {
    color: 'rgba(0,0,0,0.6)',
    fontWeight: '400',
    fontSize: '16px',
    paddingLeft: '30px',
    marginTop: '20px',
    textDecoration: 'none'
 },
 Logo: {
  height: '40px'
 }, 
 Message: {
  fontWeight: '600',
  textTransform: 'uppercase',
  fontSize: '36px',
  color: '#4d7d97',
  letterSpacing: '4px',
  textAlign: 'center',
 },
 boxRow: {
  width: '1024px',
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'start',
  alignItems: 'start',
  margin: '50px auto auto auto',
  columnGap: '22px'
 },
 Box: {  
  width: '326px',
  display: 'inline-flex',
  justifyContent: 'start',
  alignItems: 'start', 
  flexDirection: 'column'
 },
 BoxImage: {
   width: '100%'
 },
 BoxTitle: {  
  fontSize: '20px',
  lineHeight: '30px',
  textAlign: 'left',
  color: '#666'
 },
 BoxContent: {
  fontSize: '14px',
  color: '#666',
  lineHeight: '1.7em'
 },
 BoxLink: {
  color: '#2ea3f2',
  textDecoration: 'none'
 },
 //Sos
  links: {    
    display: 'flex',
    flexDirection: 'column',
    margin: theme.spacing(30, 0, 10, 0),
  },
  link: {
    fontSize: '34px',
    color: props => (props.pageType === 'baleseti'
      ? theme.palette.primary.main : theme.palette.secondary.main),
    textDecoration: 'none',
    '&:active,&:hover,&:visited': {
      textDecoration: 'none',
    },
    '&::before': {
      color: props => (props.pageType === 'baleseti'
        ? theme.palette.secondary.dark : theme.palette.primary.dark),
      content: '"• "',
      visibility: 'hidden',
    },
    '&:hover': {
      color: props => (props.pageType === 'baleseti'
        ? theme.palette.primary.dark : theme.palette.secondary.dark),
      '&::before': {
        visibility: 'visible',
        content: '"• "',
      },
    },
  },
}));

export default useStyles;

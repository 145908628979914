/* eslint-disable */
import React, {useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch, useLocation } from 'react-router-dom';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from 'history';
import HomeContainer from '../../containers/HomeContainer';
import LoginContainer from '../../containers/LoginContainer';
import RegisterContainer from '../../containers/RegisterContainer';
import SessionContainer from '../../containers/SessionContainer';
import ContentManagerContainer from '../../containers/ContentManagerContainer';
import BalesetiContentManager from '../balesetiContentManager/balesetiContentManager';
import BalesetiSearch from '../balesetiSearch/BalesetiSearch';
import SurgossegiSearch from '../surgossegiSearch/surgossegiSearch';

import ReactGA from 'react-ga';
const TRACKING_ID = "UA-242355587-1";
var history = createBrowserHistory();
ReactGA.initialize(TRACKING_ID);

const MainRouter = () => {
  //const [location, setLocation] = useState('');
  //const location = useLocation();
  useEffect(() => {
    setTimeout(()=>{
      ReactGA.pageview(location.pathname + location.search);
    }, 1000)    
  });

  return(
  <Router>
    <SessionContainer>
      <Switch>
        <Route exact path="/">
          <HomeContainer />
        </Route>
        <Route exact path="/login">
          <LoginContainer />
        </Route>
        <Route exact path="/register">
          <RegisterContainer />
        </Route>        
        <Route path="/baleseti">
          <BalesetiContentManager path={location} />
        </Route>
        <Route path="/balesetikereses">
          <BalesetiSearch />
        </Route>
        <Route path="/surgossegikereses">
          <SurgossegiSearch />
        </Route>
        <Route
          path="/:id"
          render={({ location }) => {
            const { pathname } = location;
            return (
              <ContentManagerContainer path={pathname} />
            );
          }}
        />
      </Switch>
    </SessionContainer>
  </Router>);
};

export default MainRouter;

/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Link, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { replaceVideoTag } from '../../helpers/utils';
import { useHistory } from 'react-router';
import LowerLevelLayout from '../Layouts/LowerLevelLayout/LowerLevelLayout';
import Header from '../Header/Header';
import SecondLevelMobile from './SecondLevelMobile';
import useStyles from './SecondLevel.styles';
import Balesetimainmenu from '../balesetimainmenu/balesetimainmenu';

const SecondLevel = (props) => {
  const history = useHistory();  
  const { menuItems = [], currentPage = {}, parentPage = {}, pageType = 'baleseti', language } = props;
  const { meta } = currentPage;
  const { _hu_post_title: titleHu, _en_post_title: titleEn } = meta;
  const { link: parentLink } = parentPage;
  const rightNav = { title: language === 'en' ? titleEn : titleHu, link: parentLink };

  const path = location.pathname.split('/').filter(path => path != '');
  let backpath = '/';
  for (let index = 0; index < path.length - 1; index++) {    
    backpath += path[index] + '/';
  } 

  if (props.userIsLoggedIn != null && !props.userIsLoggedIn && location.pathname.indexOf('surgossegi/szakmai') > 0) {
    history.push({
      pathname: '/login',
      state: { redirectPath: location.pathname },
    });
  }

  const handleClick = () => history.push(backpath);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.md));
  const classes = useStyles({ pageType });
  
  const handleMouseOver = selected => setSelectedPage(selected.toLowerCase());

  if (isMobile) return <SecondLevelMobile {...props} />;
  if(props.pageType == 'baleseti'){
return (        
      <div>
        <Balesetimainmenu/>     
        
        <div className={classes.mainImageContainer}>
          <div className={classes.mainImageContent}>
          <h1 className={classes.mainImageContentHeader}>{props.currentPage.meta._hu_post_title}</h1>
          <p className={classes.mainImageContentBody} dangerouslySetInnerHTML={{ __html: language === 'en' ? replaceVideoTag(props.currentPage.content.rendered) : replaceVideoTag(props.currentPage.content.rendered) }}></p>
          </div>          
        </div>      
        
        <div className={classes.boxRow}>

        {menuItems.map(menuItem => (
          <div className={classes.Box}>
          <img className={classes.BoxImage} src="/images/sport1-400x250.png"/>
          <h1 className={classes.BoxTitle}>{menuItem.nameHu}</h1>
          <p className={classes.BoxContent} dangerouslySetInnerHTML={{__html: replaceVideoTag(menuItem.excerpt.rendered)}}></p>
          <a className={classes.BoxLink} href={menuItem.link}>Bővebben</a>
          </div>          
        ))}

        </div>
          
          

          {/* <div className={classes.Box}>
          <img className={classes.BoxImage} src="/images/best_headphones_lifestyle-400x250.jpg"/>
            <h1 className={classes.BoxTitle}>Fülhallgatók és biztonság</h1>
            <p className={classes.BoxContent}>A fülhallgatók egy típusát alapvetően a fülön belül viseljük. Alacsonyabb hangerővel hasznos eszközök, de ha túl hangos zenét hallgatunk a dobhártya közelében, az sajnos tartós halláskárosodást okozhat. A másik probléma a fülhallgatókkal, hogy ha közlekedés, sportolás...</p>
            <a className={classes.BoxLink} href="/baleseti/gyermekeknek/balesetmegelozes/">Bővebben</a>
            </div>
          
          <div className={classes.Box}>
          <img className={classes.BoxImage} src="/images/tuzijatek-400x250.png"/>
            <h1 className={classes.BoxTitle}>Tűzijáték biztonságosan: Használd óvatosan!</h1>
            <p className={classes.BoxContent}>A biztonságos tűzijátékozás azelőtt kezdődik, hogy megvásárolod a tűzijátékokat. Tulajdonképpen a gyártókkal kezdődik, akinek szigorú szabályok szerint kell gyártani és ellenőrizni termékeiket. Ez sajnos nem jelenti önmagában azt, hogy a tűzijátékozás biztonságos....</p>
            <a className={classes.BoxLink} href="/baleseti/gyermekeknek/balesetmegelozes/">Bővebben</a>
            </div> */}
        </div>      
    );  
  }
  else{
      return (
        <LowerLevelLayout pageType={pageType}>
          <Header pageType={pageType} rightNav={rightNav} />
          <Box className={classes.links}>
            {menuItems.map(menuItem => (
              <Typography variant="h2" key={menuItem.id}>
                <Link component={RouterLink} className={classes.link} to={menuItem.link}>
                  {language === 'en' ? menuItem.nameEn : menuItem.nameHu}
                </Link>
              </Typography>
            ))}
          </Box>
        </LowerLevelLayout>
      );
    };
  }  

SecondLevel.propTypes = {
  menuItems: PropTypes.array,
  currentPage: PropTypes.object.isRequired,
  parentPage: PropTypes.object.isRequired,
  pageType: PropTypes.string.isRequired,
  language: PropTypes.string,
};

export default SecondLevel;

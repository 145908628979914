/* eslint-disable*/
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: props => (props.isMobile ? theme.spacing(5, 4, 0) : theme.spacing(10, 0, 0)),
    color: props => (props.pageType === 'baleseti'
      ? theme.palette.primary.dark : theme.palette.secondary.dark),
  },
  headerLeft: {
    display: 'flex',
    flexDirection: props => (props.isMobile ? 'column' : 'row'),
  },
  headerRight: {
    display: 'flex',
    alignItems: 'center',
  },
  pageTitleWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginTop: props => (props.isMobile ? theme.spacing(1) : 0),
  },
  parentLink: {
    display: 'flex',
    alignItems: 'center',
    color: props => (props.pageType === 'baleseti'
      ? theme.palette.primary.dark : theme.palette.secondary.dark),
    textDecoration: 'none',
    '&:active,&:hover,&:visited': {
      textDecoration: 'none',
    },
  },
  backButton: {
    height: 22,
    marginRight: theme.spacing(2),
  },
  logoLink: {
    display: 'flex',
    alignItems: 'center',
  },
  logo: {
    height: 40,
    [theme.breakpoints.up('xl')]: {
      height: 60,
    },
  },
  separatorPicture: {
    height: 40,
    [theme.breakpoints.up('xl')]: {
      height: 60,
    },
  },
  separator: {
    margin: props => (props.isMobile ? theme.spacing(0, 2, 0, 0) : theme.spacing(0, 2)),
    height: 40,
    [theme.breakpoints.up('xl')]: {
      height: 60,
    },
  },
  headerTitle: {
    maxWidth: props => (props.isMobile ? 150 : 180),
    lineHeight: '1.3rem',
    [theme.breakpoints.up('xl')]: {
      fontSize: theme.typography.h5.fontSize,
      lineHeight: theme.typography.h5.lineHeight,
    },
  },
  headerTitleLink: {
    textDecoration: 'none',
    color: props => (props.pageType === 'baleseti'
      ? theme.palette.primary.dark : theme.palette.secondary.dark),
    '&:active,&:hover,&:visited': {
      textDecoration: 'none',
    },
    '&:hover': {
      color: props => (props.pageType === 'baleseti'
        ? theme.palette.primary.dark : theme.palette.secondary.dark),
    },
  },  
  search: {
    width: '30px',
    height: '30px',
    marginLeft: '70px',
    marginTop: '15px',
    cursor: 'pointer',
  } 
}));

export default useStyles;

import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Container, Typography, Button, Link } from '@material-ui/core';

import Header from '../Header/Header';
import useStyles from './SecondLevelMobile.styles';

const SecondLevelMobile = (props) => {
  const {
    menuItems = [],
    currentPage = {},
    parentPage = {},
    pageType = 'baleseti',
    language,
    toggleLanguage,
    userIsLoggedIn,
  } = props;
  const { meta } = currentPage;
  const { _hu_post_title: titleHu, _en_post_title: titleEn } = meta;
  const { link: parentLink } = parentPage;
  const title = language === 'en' ? titleEn : titleHu;

  const classes = useStyles({ pageType });

  return (
    <Container className={classes.root} maxWidth="xl" disableGutters>
      <Container className={classes.content} maxWidth="xl">
        <Header pageType={pageType} isMobile language={language} toggleLanguage={toggleLanguage} userIsLoggedIn={userIsLoggedIn} />
        <Box className={classes.links}>
          <Box>
            <RouterLink to={parentLink} className={classes.parentLink}>
              <Button className={classes.parentButton} variant="outlined">
                <Typography variant="h4">
                  {title}
                </Typography>
              </Button>
            </RouterLink>
          </Box>
          {menuItems.map(menuItem => (
            <Typography className={classes.linkText} variant="h4" key={menuItem.id}>
              <Link component={RouterLink} className={classes.link} to={menuItem.link}>
                {language === 'en' ? menuItem.nameEn : menuItem.nameHu}
              </Link>
            </Typography>
          ))}
        </Box>
      </Container>
    </Container>
  );
};

SecondLevelMobile.propTypes = {
  menuItems: PropTypes.array,
  currentPage: PropTypes.object.isRequired,
  parentPage: PropTypes.object.isRequired,
  pageType: PropTypes.string.isRequired,
  language: PropTypes.string,
  toggleLanguage: PropTypes.func,
  userIsLoggedIn: PropTypes.bool,
};

export default SecondLevelMobile;

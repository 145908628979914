import TOGGLE_LANGUAGE from '../constants/language';

const getDefaultLanguage = (language) => {
  switch (language) {
    case 'hu':
    case 'hu-HU':
      return 'hu';
    case 'en':
    case 'en-EN':
      return 'en';
    default:
      return 'en';
  }
};

const initialState = {
  language: getDefaultLanguage(window.navigator.language),
};

const languageReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_LANGUAGE: {
      if (state.language === 'hu') return { language: 'en' };
      return { language: 'hu' };
    }
    default:
      return state;
  }
};

export default languageReducer;

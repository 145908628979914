/* eslint-disable */
import {
  REQUEST_GET_MENU,
  GET_MENU_SUCCESS,
  GET_MENU_ERROR,
  REQUEST_GET_INTROS,
  GET_GET_INTROS_SUCCESS,
  GET_GET_INTROS_ERROR,
  REQUEST_GET_SUBMENUS,
  GET_SUBMENUS_SUCCESS,
  GET_SUBMENUS_ERROR,
  REQUEST_GET_BALESETIPAGE,
  GET_BALESETIPAGE_SUCCESS,
  GET_BALESETIPAGE_ERROR,
  REQUEST_SEARCH_BALESETIPAGE,
  SEARCH_BALESETIPAGE_SUCCESS,
  SEARCH_BALESETIPAGE_ERROR,
} from '../constants/balesetpage';

const initialState = {
  isFetching: false,
  errorMessage: null,
  content: null,
  currentPage: null,
  menuItems: [],
  subMenuItems: [],
  Intros: null,
  parent: null,
  footer: null,
  searchResult: [],
};

const balesetipageReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_GET_INTROS: {
      return { ...state, isFetching: true };
    }
    case GET_GET_INTROS_SUCCESS: {
      const { Intros } = action;
      return {
        ...state,
        isFetching: false,
        Intros,
      };
    }
    case GET_GET_INTROS_ERROR: {
      const { error } = action;
      return {
        ...state,
        isFetching: false,
        errorMessage: error,
      };
    }

    case REQUEST_GET_MENU: {
      return { ...state, isFetching: true };
    }
    case GET_MENU_SUCCESS: {
      const { menuItems } = action;
      return {
        ...state,
        isFetching: false,
        menuItems,
      };
    }
    case GET_MENU_ERROR: {
      const { error } = action;
      return {
        ...state,
        isFetching: false,
        errorMessage: error,
      };
    }

    case REQUEST_GET_SUBMENUS: {
      return { ...state, isFetching: true };
    }
    case GET_SUBMENUS_SUCCESS: {
      const { subMenuItems } = action;
      return {
        ...state,
        isFetching: false,
        subMenuItems,
      };
    }
    case GET_SUBMENUS_ERROR: {
      const { error } = action;
      return {
        ...state,
        isFetching: false,
        errorMessage: error,
      };
    }

    case REQUEST_GET_BALESETIPAGE: {
      return { ...state, isFetching: true };
    }
    case GET_BALESETIPAGE_SUCCESS: {
      const { balesetiPageContent } = action;
      return {
        ...state,
        isFetching: false,
        balesetiPageContent,
      };
    }
    case GET_BALESETIPAGE_ERROR: {
      const { error } = action;
      return {
        ...state,
        isFetching: false,
        errorMessage: error,
      };
    }

    //
    case REQUEST_SEARCH_BALESETIPAGE: {
      return { ...state, isFetching: true };
    }
    case SEARCH_BALESETIPAGE_SUCCESS: {
      const { searchResult } = action;
      return {
        ...state,
        isFetching: false,
        searchResult,
      };
    }
    case SEARCH_BALESETIPAGE_ERROR: {
      const { error } = action;
      return {
        ...state,
        isFetching: false,
        errorMessage: error,
      };
    }
    default:
      return state;
  }
};

export default balesetipageReducer;
import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

import resources from '../locales/translations.json';

i18next.use(initReactI18next).init({
  resources,
  lng: window.navigator.language,
  fallbackLng: 'hu',
  // detection: {
  //   order: ['querystring'],
  // },
  supportedLngs: ['hu'],
  interpolation: {
    formatSeparator: '|',
  },
});

export default i18next;

import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { Container, Typography, Box, Link } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import Image from '../../Image/Image';
import Header from '../Header/Header';
import useStyles from './Content.styles';

const Content = ({ userIsLoggedIn, userName }) => {
  const classes = useStyles();
  const { t } = useTranslation(['homepage']);
  return (
    <>
      <Header userIsLoggedIn={userIsLoggedIn} userName={userName} />
      <Container className={classes.root} maxWidth="lg" disableGutters>
        <Box className={classes.contentWrapper}>
          <Box className={classes.contentLeft}>
            <Image className={classes.image} pictureClassName={classes.image} src="home_bg" alt="Országos Gyermekbaleseti és Gyermeksürgősségi program" />
          </Box>
          <Box className={classes.contentRight}>
            <Typography variant="h1" className={classes.title}>
              {t('title')}
            </Typography>
            <Box className={classes.linkContainer}>
              <Link component={RouterLink} className={`${classes.linkAccident} ${classes.link}`} to="/baleseti">
                {t('nav.accident')}
              </Link>
              <Typography variant="h4" className={classes.linkSeparator}>
                {t('nav.or')}
              </Typography>
              <Link component={RouterLink} className={`${classes.linkSOS} ${classes.link}`} to="/surgossegi">
                {t('nav.sos')}
              </Link>
            </Box>
          </Box>
        </Box>
      </Container>
    </>
  );
};

Content.propTypes = {
  userIsLoggedIn: PropTypes.bool.isRequired,
  userName: PropTypes.string,
};

export default Content;

/* eslint-disable */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { Box, Container, IconButton, Link, Typography, useMediaQuery, useTheme } from '@material-ui/core';
//import Image from '../Image/Image';
//import Header from '../Header/Header';
import useStyles from './balesetimainmenu.styles';
//import balesetimainmenuMobile from './balesetimainmenuMobile';
//import { getPageContent } from '../../redux/actions/page';
import { getMenu } from '../../redux/actions/balesetpage';


const Balesetimainmenu = (props) => {  
  const classes = useStyles();
  useState('prevPath');
  useEffect(() => {    
    props.getMenu(35);     
  }, []);

  

  return (        
    <div className={classes.MenuContainer}>
          <img className={classes.Logo} src="/images/logo.png"/>      
        {props.menuItems.map((menuItem,index) => (
        <p key={index} className={classes.upperMenuLink}><a className={classes.upperMenuLink} href={menuItem.link}>{menuItem.nameHu}</a>        
        </p>               
        ))}        
        <div><a href={"/balesetikereses?backurl="+location.pathname + '&term='}><img className={classes.search} src="/images/Search_Icon.png"/></a></div>
        {/* <Link component={RouterLink} to={{pathName: '/balesetikereses', state: { prevPath: location.pathname }}}><img src="/images/Search_Icon.png" className={classes.search}/></Link> */}
      </div>
  );      
};

Balesetimainmenu.propTypes = {
  //menuItems: PropTypes.array.isRequired,
  //pageType: PropTypes.string.isRequired,
  //language: PropTypes.string,
};

const mapStateToProps = ({ balesetipageReducer, languageReducer, userReducer }, ownProps) => {
  return {    
    menuItems: balesetipageReducer.menuItems
  }
}

const mapDispatchToProps = () => {
  return {
    getMenu
  }
}

export default connect(mapStateToProps, mapDispatchToProps())(Balesetimainmenu);

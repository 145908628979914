/* eslint-disable */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useHistory } from 'react-router-dom';
import { IconButton, Drawer, Box, Button, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import Image from '../Image/Image';
import { logOut } from '../../helpers/utils';
import useStyles from './MobileMenu.styles';

const MenuIcon = ({ pageType = 'surgossegi', className }) => (
  <Image className={className} pictureClassName={className} src={`menu_${pageType}`} alt="menu icon" />
);

const MobileMenu = ({ pageType, toggleLanguage, language, userIsLoggedIn }) => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation(['menu']);
  const selectedLanguage = language === 'hu' ? 'ENG' : 'HUN';
  const assetPageType = ['surgossegi', 'home'].includes(pageType) ? 'surgossegi' : 'baleseti';

  const handleClick = () => toggleLanguage(language);

  const [open, setOpen] = useState(false);
  const toggleDrawer = () => setOpen(v => !v);
  return (
    <>
      {pageType && pageType !== 'home' && <Image className={classes.cornerImage} src={`mobile_corner_${assetPageType}`} alt="corner" />}
      <IconButton onClick={toggleDrawer} className={classes.iconButton}>
        <MenuIcon className={classes.menuIcon} pageType={assetPageType} />
      </IconButton>
      <Drawer classes={{ paper: classes.drawer }} anchor="right" open={open} onClose={toggleDrawer}>
        <Box className={classes.top} onClick={() => history.push('/')}>
          <Image className={classes.logo} pictureClassName={classes.logo} src="logo" alt="logo" />
          <div className={classes.searchContainer}><a href={"/surgossegikereses?backurl="+location.pathname + '&term='}><img className={classes.search} src="/images/Search_Icon.png"/></a></div>
          <Box>
            {userIsLoggedIn && (
              <Button onClick={() => logOut()} className={classes.logoutButton} aria-label="logout" color="error">
                <Typography className={classes.initials} variant="caption">{t('logout')}</Typography>
              </Button>
            )}
            <IconButton onClick={toggleDrawer}>
              <Image className={classes.menuIcon} pictureClassName={classes.menuIcon} src="menu_close" alt="menu close icon" />
            </IconButton>
          </Box>
        </Box>
        <Box className={classes.linkWrapper}>
          <Box className={classes.linkBlock}>
            <Box>
              <Link className={classes.link} to="/misszionk">
                <Button className={classes.linkButton} variant="outlined">{t('mission')}</Button>
              </Link>
            </Box>
            {/* <Box>
              <Link className={classes.link} to="/dokumentaciok">
                <Button className={classes.linkButton} variant="outlined">{t('documentations')}</Button>
              </Link>
            </Box> */}
            <Box>
              <Link className={classes.link} to="/kapcsolat">
                <Button className={classes.linkButton} variant="outlined">{t('contact')}</Button>
              </Link>
            </Box>
          </Box>
          <Box className={classes.linkBlock}>
            <Button className={`${classes.linkButton} ${classes.linkTitleSos}`} to="/surgossegi">
              {t('sosTitle')}
            </Button>
            {/* <Box>
              <Link className={classes.link} to="/surgossegi/gyermekeknek">
                <Button className={classes.linkButton} variant="outlined">{t('children')}</Button>
              </Link>
            </Box> */}
            <Box>
              <Link className={classes.link} to="/surgossegi/szakmai">
                <Button className={classes.linkButton} variant="outlined">{t('professional')}</Button>
              </Link>
            </Box>
            <Box>
              <Link className={classes.link} to="/surgossegi/szuloknek">
                <Button className={classes.linkButton} variant="outlined">{t('parents')}</Button>
              </Link>
            </Box>
          </Box>
          <Box className={classes.linkBlock}>
            <Button className={`${classes.linkButton} ${classes.linkTitleAccident}`} to="/baleseti">
              {t('accidentTitle')}
            </Button>
            <Box>
              <Link className={classes.link} to="/baleseti/gyakori-balesettipusok/">
                <Button className={classes.linkButton} variant="outlined">Gyakori balesettípusok</Button>
              </Link>
            </Box>
            <Box>
              <Link className={classes.link} to="/baleseti/kisiskolasok/">
                <Button className={classes.linkButton} variant="outlined">Kisiskolások</Button>
              </Link>
            </Box>
            <Box>
              <Link className={classes.link} to="/baleseti/szulok-nevelok/">
                <Button className={classes.linkButton} variant="outlined">Szülők, nevelők</Button>
              </Link>
            </Box>
            <Box>
              <Link className={classes.link} to="/baleseti/serduloknek/">
                <Button className={classes.linkButton} variant="outlined">Serdülők</Button>
              </Link>
            </Box>
            <Box>
              <Link className={classes.link} to="/baleseti/vedonok-gyermekorvosok/">
                <Button className={classes.linkButton} variant="outlined">Védőnők, gyermekorvosok</Button>
              </Link>
            </Box>
            <Box>
              <Link className={classes.link} to="/baleseti/baleseti-szakmai/">
                <Button className={classes.linkButton} variant="outlined">Baleseti ellátók</Button>
              </Link>
            </Box>
          </Box>
          {/* <Box className={classes.langButtonWrapper}>
            <Button onClick={handleClick} className={classes.langButton} variant="outlined">
              {selectedLanguage}
            </Button>
          </Box> */}
        </Box>
      </Drawer>
    </>
  );
};

MenuIcon.propTypes = {
  pageType: PropTypes.string,
  className: PropTypes.string,
};

MobileMenu.propTypes = {
  pageType: PropTypes.string,
  toggleLanguage: PropTypes.func,
  language: PropTypes.string,
  userIsLoggedIn: PropTypes.bool,
};

export default MobileMenu;

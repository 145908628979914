import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100vh',
    backgroundColor: props => theme.palette[props.pageType === 'surgossegi' ? 'secondary' : 'primary'].light,
  },
  bgImage: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    zIndex: theme.zIndex.appBar,
    width: '100%',
    maxWidth: 600,
    maxHeight: '60%',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    zIndex: theme.zIndex.drawer,
    minHeight: '100vh',
  },
  links: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(6),
    padding: theme.spacing(0, 4),
  },
  link: {
    textDecoration: 'none',
    '&:active,&:hover,&:visited': {
      textDecoration: 'none',
    },
  },
  linkButton: {
    margin: theme.spacing(0.5, 0),
    borderRadius: 30,
    borderWidth: 3,
    padding: theme.spacing(0, 2),
    textTransform: 'none',
    color: props => (props.pageType === 'baleseti'
      ? theme.palette.primary.dark : theme.palette.secondary.dark),
    borderColor: props => (props.pageType === 'baleseti'
      ? theme.palette.primary.dark : theme.palette.secondary.dark),
    textDecoration: 'none',
  },
  linkText: {
    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: theme.typography.h3.fontSize,
    },
  },
}));

export default useStyles;

/* eslint-disable */
import {
  REQUEST_GET_MENU,
  GET_MENU_SUCCESS,
  GET_MENU_ERROR,
  REQUEST_GET_INTROS,
  GET_GET_INTROS_SUCCESS,
  GET_GET_INTROS_ERROR,
  REQUEST_GET_SUBMENUS,
  GET_SUBMENUS_SUCCESS,
  GET_SUBMENUS_ERROR,
  REQUEST_GET_BALESETIPAGE,
  GET_BALESETIPAGE_SUCCESS,
  GET_BALESETIPAGE_ERROR,
  REQUEST_SEARCH_BALESETIPAGE,
  SEARCH_BALESETIPAGE_SUCCESS,
  SEARCH_BALESETIPAGE_ERROR,
} from '../constants/balesetpage';
import { callApi, getCookies, handleErrors } from '../../helpers/utils';
import { wpStructureLevels, wpDesigns, errorCodes } from '../../constants/constants';

const createLink = (link = '') => {
  if (!link || link === '') return '';
  const linkItems = link.split('fooldal');
  if (!linkItems || linkItems.length === 0) return link;
  return linkItems[linkItems.length - 1];
};

const getPartOfPath = (path = '', returnLast = true) => {
  if (!path || path === '') return 'fooldal';
  const pathItems = path.split('/').filter(value => value !== '');
  if (!pathItems || pathItems.length === 0) return 'fooldal';
  if (returnLast) {
    return pathItems[pathItems.length - 1];
  }
  return pathItems[0];
};

const getExactResult = (data = [], path = '') => {
  if (!data) return {};
  if (data.length === 1) return data[0];
  return data.filter(({ acf: customFields }) => {
    const { page_type: pageType } = customFields;
    const currentPageType = getPartOfPath(path, false);
    return pageType === currentPageType;
  })[0];
};

const checkPermission = (page = {}) => {
  if (!page) return false;
  const { permission } = getCookies();
  const requiredPermissions = page['simple-restrict-permission'] || [];
  if (requiredPermissions?.length) {
    return !!requiredPermissions.includes(parseInt(permission, 10));
  }
  return true;
};

const requestGetIntros = () => ({
  type: REQUEST_GET_INTROS,
});

const GetIntrosSuccess = Intros => ({
  type: GET_GET_INTROS_SUCCESS,
  Intros,
});

const GetIntrosError = error => ({
  type: GET_GET_INTROS_ERROR,
  error,
});

export const getIntros = (path = 'baleseti/serduloknek/') => async (dispatch) => {
  dispatch(requestGetIntros());
  try {
    const { REACT_APP_API_PREFIX: apiPrefix } = process.env;
    const slug = getPartOfPath(path);
    const { data = [] } = await callApi(
      {
        method: 'GET',
        url: `/wp-json/wp/v2/pages?slug=${slug}`,
      },
      apiPrefix,
    );

    if (!data || data.length === 0) {
      dispatch(getPageContentError(errorCodes.NO_PAGE_DATA));
      return;
    }

    const page = getExactResult(data, path);

    if (!checkPermission(page)) {
      dispatch(getPageContentError(errorCodes.ACCESS_DENIED));
      return;
    }

    const {
      id: pageId,
      slug: pageSlug,
      status,
      content,
      parent: parentId,
      meta,
      acf,
      link,
      date
    } = page;

    dispatch(getSubMenus(pageId));

    const {
      page_type: pageType = wpDesigns.emergency,
      structure_level: structureLevel = wpStructureLevels.firstLevel,
    } = acf;

    dispatch(
      GetIntrosSuccess({
        pageId,
        slug: pageSlug,
        isPublished: status === 'publish',
        content,
        parentId,
        meta,
        pageType,
        structureLevel,
        date,
        link: createLink(link),
      }),
    );
  } catch (error) {
    if (process.env.NODE_ENV === "development") console.error(error); // eslint-disable-line
    dispatch(GetIntrosError(handleErrors(error)));
  }
};

const requestGetMenu = () => ({
  type: REQUEST_GET_MENU,
});

const getMenuSuccess = menuItems => ({
  type: GET_MENU_SUCCESS,
  menuItems,
});

const getMenuError = error => ({
  type: GET_MENU_ERROR,
  error,
});

export const getMenu = (id = 35) => async (dispatch) => {
  dispatch(requestGetMenu());
  try {
    const { REACT_APP_API_PREFIX: apiPrefix } = process.env;
    const { data = [] } = await callApi(
      {
        method: 'GET',
        url: `/wp-json/wp/v2/pages?parent=${id}&_fields=id,meta,menu_order,link,_links,slug,content,excerpt,_meta,source_url,simple-restrict-permission&per_page=100&_embed`,
      },
      apiPrefix,
    );

    const menuItems = data.map(
      ({ id: childId, meta, menu_order: order, link, slug, content, excerpt, _links, _embedded }) => {
        const { _hu_post_title: nameHu, _en_post_title: nameEn } = meta;
        return {
          id: childId,
          slug,
          nameHu,
          nameEn: nameEn || nameHu,
          order,
          link: createLink(link),
          content,
          excerpt,
          _links,
          _embedded
        };
      },
    );

    menuItems.sort(({ order: firstMenuOrder }, { order: secondMenuOrder }) => firstMenuOrder - secondMenuOrder);
    dispatch(getMenuSuccess(menuItems));
  } catch (error) {
    if (process.env.NODE_ENV === "development") console.error(error); // eslint-disable-line
    dispatch(getMenuError(handleErrors(error)));
  }
};


const requestGetSubMenus = () => ({
  type: REQUEST_GET_SUBMENUS,
});

const getSubMenusSuccess = subMenuItems => ({
  type: GET_SUBMENUS_SUCCESS,
  subMenuItems,
});

const getSubMenusError = error => ({
  type: GET_SUBMENUS_ERROR,
  error,
});

export const getSubMenus = (id = 35) => async (dispatch) => {
  dispatch(requestGetSubMenus());
  try {
    const { REACT_APP_API_PREFIX: apiPrefix } = process.env;
    const { data = [] } = await callApi(
      {
        method: 'GET',
        url: `/wp-json/wp/v2/pages?parent=${id}&_fields=id,meta,menu_order,link,_links,slug,content,excerpt,_meta,date,source_url,simple-restrict-permission&per_page=100&_embed`,
      },
      apiPrefix,
    );

    const subMenuItems = data.map(
      ({ id: childId, meta, menu_order: order, link, slug, content, excerpt, _links, _embedded, date }) => {
        const { _hu_post_title: nameHu, _en_post_title: nameEn } = meta;
        return {
          id: childId,
          slug,
          nameHu,
          nameEn: nameEn || nameHu,
          order,
          link: createLink(link),
          content,
          excerpt,
          _links,
          date,
          _embedded
        };
      },
    );
    subMenuItems.sort(({ order: firstMenuOrder }, { order: secondMenuOrder }) => firstMenuOrder - secondMenuOrder);
    dispatch(getSubMenusSuccess(subMenuItems));
  } catch (error) {
    if (process.env.NODE_ENV === "development") console.error(error); // eslint-disable-line
    dispatch(getSubMenusError(handleErrors(error)));
  }
};


const requestGetBalesetiPageContent = () => ({
  type: REQUEST_GET_BALESETIPAGE,
});

const GetBalesetiPageContentSuccess = balesetiPageContent => ({
  type: GET_BALESETIPAGE_SUCCESS,
  balesetiPageContent,
});

const GetBalesetiPageContentError = error => ({
  type: GET_BALESETIPAGE_ERROR,
  error,
});

export const GetBalesetiPageContent = (path = '') => async (dispatch) => {
  dispatch(requestGetBalesetiPageContent());
  try {
    const { REACT_APP_API_PREFIX: apiPrefix } = process.env;
    const slug = getPartOfPath(path);
    const { data = [] } = await callApi(
      {
        method: 'GET',
        url: `/wp-json/wp/v2/pages?slug=${slug}&_embed`,
      },
      apiPrefix,
    );

    if (!data || data.length === 0) {
      dispatch(GetBalesetiPageContentError(errorCodes.NO_PAGE_DATA));
      return;
    }

    const PageContent = data[0]

    const {
      id: pageId,
      slug: pageSlug,
      status,
      content,
      parent: parentId,
      meta,
      acf,
      link,
      _embedded,
      _links,
      date,
      date_gmt
    } = PageContent;

    const {
      page_type: pageType = wpDesigns.emergency,
      structure_level: structureLevel = wpStructureLevels.firstLevel,
    } = acf;

    dispatch(
      GetBalesetiPageContentSuccess({
        pageId,
        slug: pageSlug,
        isPublished: status === 'publish',
        content,
        parentId,
        meta,
        pageType,
        structureLevel,
        link: createLink(link),
        _links,
        _embedded,
        date,
        date_gmt
      }),
    );
  } catch (error) {
    if (process.env.NODE_ENV === "development") console.error(error); // eslint-disable-line
    dispatch(GetBalesetiPageContentError(handleErrors(error)));
  }
};


const requestSearchBalesetiPageContent = () => ({
  type: REQUEST_SEARCH_BALESETIPAGE,
});

const SearchBalesetiPageContentSuccess = searchResult => ({
  type: SEARCH_BALESETIPAGE_SUCCESS,
  searchResult,
});

const SearchBalesetiPageContentError = error => ({
  type: SEARCH_BALESETIPAGE_ERROR,
  error,
});

export const SearchBalesetiPageContent = (path = '') => async (dispatch) => {
  dispatch(requestSearchBalesetiPageContent());
  try {
    const { REACT_APP_API_PREFIX: apiPrefix } = process.env;
    const slug = getPartOfPath(path);
    const { data = [] } = await callApi(
      {
        method: 'GET',
        url: `/wp-json/wp/v2/pages?search=${slug}&_embed`,
      },
      apiPrefix,
    );

    if (!data || data.length === 0) {
      dispatch(SearchBalesetiPageContentError(errorCodes.NO_PAGE_DATA));
      return;
    }

    // const SearchResult = data[0]

    // const {
    //   id: pageId,
    //   slug: pageSlug,
    //   status,
    //   content,
    //   parent: parentId,
    //   meta,
    //   acf,
    //   link,
    //   _embedded,
    //   _links,
    //   date,
    //   date_gmt
    // } = SearchResult;

    // const {
    //   page_type: pageType = wpDesigns.emergency,
    //   structure_level: structureLevel = wpStructureLevels.firstLevel,
    // } = acf;

    const SearchResult = data.map(
      ({ id: pageId, meta, menu_order: order, link, slug, excerpt, _links, _embedded, date }) => {
        const { _hu_post_title: nameHu, _en_post_title: nameEn } = meta;
        return {
          id: pageId,
          slug,
          nameHu,
          nameEn: nameEn || nameHu,
          order,
          link: createLink(link),          
          excerpt,
          _links,
          date,
          _embedded
        };
      },
    );
    //subMenuItems.sort(({ order: firstMenuOrder }, { order: secondMenuOrder }) => firstMenuOrder - secondMenuOrder);

    dispatch(
      SearchBalesetiPageContentSuccess(SearchResult),
    );
  } catch (error) {
    if (process.env.NODE_ENV === "development") console.error(error); // eslint-disable-line
    dispatch(SearchBalesetiPageContentError(handleErrors(error)));
  }
};
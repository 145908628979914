/* eslint-disable */
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: '100vh',
    backgroundColor: props => (
      props.pageType === 'baleseti' ? theme.palette.primary.light : theme.palette.secondary.light
    ),
    position: 'relative',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    zIndex: theme.zIndex.drawer,
    minHeight: '100vh',
  },
  parentButton: {
    margin: theme.spacing(0.5, 0),
    borderRadius: 30,
    borderWidth: 3,
    padding: theme.spacing(0, 2),
    textTransform: 'none',
    color: props => (props.pageType === 'baleseti'
      ? theme.palette.primary.dark : theme.palette.secondary.dark),
    borderColor: props => (props.pageType === 'baleseti'
      ? theme.palette.primary.dark : theme.palette.secondary.dark),
    textDecoration: 'none',
  },
  links: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
    padding: theme.spacing(0, 0),
  },
  parentLink: {
    color: props => (props.pageType === 'baleseti'
      ? theme.palette.primary.dark : theme.palette.secondary.dark),
    textDecoration: 'none',
    '&:active,&:hover,&:visited': {
      textDecoration: 'none',
    },
    marginBottom: theme.spacing(4),
  },
  link: {
    color: props => (props.pageType === 'baleseti'
      ? theme.palette.primary.dark : theme.palette.secondary.dark),
    textDecoration: 'none',
    '&:active,&:hover,&:visited': {
      textDecoration: 'none',
    },
    '&::before': {
      content: '"• "',
    },
  },
  linkText: {
    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: theme.typography.h3.fontSize,
    },
  },
}));

export default useStyles;

import React from 'react';
import { Container, Typography, Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import Header from '../Header/Header';
import useStyles from './NotFound.styles';

const NotFound = () => {
  const { t } = useTranslation(['404']);
  const classes = useStyles();
  return (
    <Container className={classes.root} maxWidth="xl">
      <Container className={classes.contentWrapper} maxWidth="md">
        <Header />
        <Box className={classes.content}>
          <Typography variant="h1">404</Typography>
          <Typography variant="h2" className={classes.subTitle}>
            {t('title')}
          </Typography>
        </Box>
      </Container>
    </Container>
  );
};

export default NotFound;

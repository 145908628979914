import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    padding: props => (props.isMobile ? theme.spacing(5, 0, 0) : theme.spacing(10, 10, 0)),
    display: 'flex',
    justifyContent: 'space-between',
    position: 'relative',
  },
  logo: {
    height: 40,
    [theme.breakpoints.up('xl')]: {
      height: 60,
    },
  },
  link: {
    textDecoration: 'none',
    marginLeft: theme.spacing(5),
    '&:hover,:active,:visited': {
      textDecoration: 'none',
    },
    color: theme.palette.grey[600],
    [theme.breakpoints.up('xl')]: {
      fontSize: theme.typography.h5.fontSize,
    },
  },
  logoutButton: {
    marginLeft: theme.spacing(5),
    color: theme.palette.secondary.dark,
    border: `2px solid ${theme.palette.secondary.dark}`,
    padding: 0,
    '&:hover $logoutText': {
      display: 'block',
    },
  },
  logoutText: {
    display: 'none',
    position: 'absolute',
    marginBottom: theme.spacing(-5),
  },
  initials: {
    width: 24,
  },
}));

export default useStyles;

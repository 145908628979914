import { connect } from 'react-redux';
import Home from '../components/Home/Home';
import toggleLanguage from '../redux/actions/language';
import { getFooterContent } from '../redux/actions/page';

const mapStateToProps = ({ pageReducer, sessionReducer, userReducer, languageReducer }) => ({
  isFetching: pageReducer.isFetching,
  errorMessage: pageReducer.errorMessage,
  hasSession: sessionReducer.hasSession,
  isSessionFetching: sessionReducer.isFetching,
  footer: pageReducer.footer,
  userIsLoggedIn: userReducer.userIsLoggedIn,
  language: languageReducer.language,
  userName: userReducer.userName,
});

const mapDispatchToProps = dispatch => ({
  getFooterContent: () => dispatch(getFooterContent()),
  toggleLanguage: lang => dispatch(toggleLanguage(lang)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);

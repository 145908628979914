import { connect } from 'react-redux';
import ContentManager from '../components/ContentManager/ContentManager';
import { getPageContent } from '../redux/actions/page';
import toggleLanguage from '../redux/actions/language';

const mapStateToProps = ({ pageReducer, languageReducer, userReducer }, ownProps) => ({
  currentPage: pageReducer.currentPage,
  parentPage: pageReducer.parent,
  menuItems: pageReducer.menuItems,
  isFetching: pageReducer.isFetching,
  errorMessage: pageReducer.errorMessage,
  path: ownProps.path,
  language: languageReducer.language,
  userIsLoggedIn: userReducer.userIsLoggedIn,
});

const mapDispatchToProps = dispatch => ({
  getPageContent: path => dispatch(getPageContent(path)),
  toggleLanguage: lang => dispatch(toggleLanguage(lang)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContentManager);

/* eslint-disable */
import React, { useState, useEffect } from 'react';
import BalesetiFirstLevel from '../balesetiFirstLevel/BalesetiFirstLevel';
import BalesetiSecondLevel from '../balesetiSecondLevel/BalesetiSecondLevel';
import BalesetiThirdLevel from '../balesetiThirdLevel/BalesetiThirdLevel';

const BalesetiContentManager = (props) => {

  const Depth = props.path.pathname.split('/').filter(text => text != '').length;
  const IsVedono = props.path.pathname.indexOf('vedonok-gyermekorvosok') > 0;
  const IsEloadasok = props.path.pathname.indexOf('eloadasok-vetitesek') > 0;

  if (Depth == 1) {
    return (
      <BalesetiFirstLevel />
    )
  }
  else if (Depth == 2 || (Depth == 3 && IsVedono && !IsEloadasok)) {
    return (
      <BalesetiSecondLevel />
    )
  }
  else if (Depth == 3 || Depth == 4) {
    return (
      <BalesetiThirdLevel />
    )
  }

};

export default BalesetiContentManager;

/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { Button, Container, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import Image from '../../Image/Image';
import useStyles from './Footer.styles';

const getContent = (data, language) => {
  const { meta } = data;
  //const { _hu_post_content: contentHu, _en_post_content: contentEn } = meta;
  const { _hu_post_content: contentHu } = meta;
  //return language === 'en' ? contentEn : contentHu;
  return contentHu;
};

const Footer = ({ data, language, toggleLanguage, isLoading, isReady }) => {
  //const selectedLanguage = language === 'hu' ? 'English' : 'Magyar';
  const { t } = useTranslation(['footer']);
  const classes = useStyles();

  return (
    <Container className={classes.root} maxWidth={false} disableGutters>
      <Image className={classes.logo} src="eu_logo" alt="eu logo" />
      <Container className={classes.wrapper} maxWidth="lg">
        <div className={classes.leftContent}>
          {isLoading ? <p>loading</p> : isReady ? <div dangerouslySetInnerHTML={{ __html: getContent(data, language) }} /> : <p>Error</p>}
        </div>
        <div className={classes.rightContent}>
          <Typography className={classes.phoneTitle} variant="body1">{t('phoneTitle')}</Typography>
          <Typography className={classes.phoneNumber} variant="body1">{t('phoneNumber')}</Typography>
          <Image className={classes.heimPalLogo} src="logo" alt="logo" />
          {/* <Button className={classes.languageButton} variant="outlined" color="primary" onClick={() => toggleLanguage(language)}>
            {selectedLanguage}
          </Button> */}
        </div>
      </Container>
    </Container>
  );
};

Footer.propTypes = {
  language: PropTypes.string,
  toggleLanguage: PropTypes.func,
  data: PropTypes.object,
  isLoading: PropTypes.bool,
  isReady: PropTypes.bool,
};

export default Footer;

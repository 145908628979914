import i18next from 'i18next';

import TOGGLE_LANGUAGE from '../constants/language';

const switchLanguage = () => ({
  type: TOGGLE_LANGUAGE,
});

const toggleLanguage = language => (dispatch) => {
  const nextLang = language === 'hu' ? 'en' : 'hu';
  i18next.changeLanguage(nextLang, () => {
    dispatch(switchLanguage());
  });
};

export default toggleLanguage;

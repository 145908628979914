import { combineReducers } from 'redux';
import pageReducer from './page';
import balesetipageReducer from './balesetpage';
import sessionReducer from './session';
import languageReducer from './language';
import userReducer from './user';

export default combineReducers({
  pageReducer,
  balesetipageReducer,
  sessionReducer,
  languageReducer,
  userReducer,
});

/* eslint-disable */
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  //Accident
  mainImageContainer: {    
    maxWidth: '100%',
    height: '700px',
    overflow: 'hidden',
    backgroundImage: 'url("/images/serdulokHeader.png")',
    backgroundSize: 'cover',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'start' 
  }, 
  mainImageContent: {
    width: '25%',    
    color: 'white'
  },
  MenuContainer: {
    height: '100px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'   
  },  
  upperMenuLink: {
    color: 'rgba(0,0,0,0.6)',
    fontWeight: '400',
    fontSize: '16px',
    paddingLeft: '30px',
    marginTop: '20px',
    textDecoration: 'none'
 },
 Logo: {
  height: '40px'
 }, 
 Message: {
  fontWeight: '600',
  textTransform: 'uppercase',
  fontSize: '36px',
  color: '#4d7d97',
  letterSpacing: '4px',
  textAlign: 'center',
 },
 boxRow: {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'start',
  margin: '0 0 50px 0' 
 },
 Box: {  
  width: '300px',  
  margin: '30px 50px 0 30px',  
  border: '1px solid #d8d8d8'  
 },
 BoxImage: {
   width: '100%'
 },
 BoxTitle: {  
  fontSize: '20px',
  lineHeight: '30px',
  textAlign: 'left',
  color: '#666',
  margin: '0 10px 10px 10px'
 },
 BoxContent: {
  fontSize: '14px',
  color: '#666',
  lineHeight: '1.7em',
  margin: '0 10px 10px 10px'
 },
 BoxLink: {
  color: '#2ea3f2',
  textDecoration: 'none',
  margin: '0 0 0 10px'
 },
 articleContainer: {
   width: '40%',
   margin: 'auto auto',
   display: 'flex',
   flexDirection: 'column',
   justifyContent: 'start',
   alignItems: 'start',
   color: '#666',
   lineHeight: '1.7em',
   fontWeight: '500'
 },
 articleImage: {
  width: '100%'
 },
 //Sos
  content: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(10),
    zIndex: theme.zIndex.drawer,
    marginLeft: theme.spacing(20),
    marginRight: theme.spacing(20),
    [theme.breakpoints.up('lg')]: {
      marginRight: theme.spacing(60),
    },
    [theme.breakpoints.up('xl')]: {
      marginLeft: theme.spacing(29),
      marginRight: theme.spacing(70),
    },
  },
  title: {
    fontSize: '34px',
    fontWeight: 'bold',
    marginBottom: '20px',
    color: props => (props.pageType === 'baleseti'
      ? theme.palette.primary.dark : theme.palette.secondary.dark),
    '&::before': {
      position: 'absolute',
      left: theme.spacing(-4),
      color: props => (props.pageType === 'baleseti'
        ? theme.palette.secondary.dark : theme.palette.primary.dark),
      content: '"• "',
    },
  },
  wpContent: {
    fontSize: '15px',
    color: props => (props.pageType === 'baleseti'
      ? theme.palette.primary.dark : theme.palette.secondary.dark),
    '& table': {
      borderCollapse: 'collapse',
    },
    '& td': {
      padding: theme.spacing(1),
      border: props => `1px solid ${props.pageType === 'baleseti'
        ? theme.palette.primary.dark : theme.palette.secondary.dark}`,
    }
  },
  link: {
    fontSize: theme.typography.h6.fontSize,
    color: props => (props.pageType === 'baleseti'
      ? theme.palette.primary.main : theme.palette.secondary.main),
    textDecoration: 'none',
    '&:active,&:hover,&:visited': {
      textDecoration: 'none',
    },
    '&::before': {
      color: props => (props.pageType === 'baleseti'
        ? theme.palette.primary.dark : theme.palette.secondary.dark),
      content: '"• "',
    },
    '&:hover': {
      color: props => (props.pageType === 'baleseti'
        ? theme.palette.primary.dark : theme.palette.secondary.dark),
    },
  },
  missionSos: {
    fontSize: '5px',
    color: 'red',
  }
}));

export default useStyles;

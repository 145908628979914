import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    height: 'calc(100vh - 120px)',
    maxHeight: 'calc(100vh - 120px)',
    [theme.breakpoints.up('xl')]: {
      height: 'calc(100vh - 140px)',
      maxHeight: 'calc(100vh - 140px)',
    },
  },
  contentWrapper: {
    height: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  contentLeft: {
    height: '100%',
    display: 'flex',
    alignItems: 'flex-end',
  },
  image: {
    height: '100%',
    maxHeight: 900,
  },
  contentRight: {
    maxWidth: 500,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    margin: theme.spacing(20, 10, 6, 0),
    position: 'relative',
  },
  title: {
    textAlign: 'end',
    fontWeight: 900,
    color: theme.palette.secondary.dark,
    textTransform: 'uppercase',
    position: 'absolute',
    top: 0,
    right: 0,
    minWidth: 700,
    fontSize: theme.typography.h3.fontSize,
    [theme.breakpoints.down(1050)]: {
      fontSize: theme.typography.h4.fontSize,
      minWidth: 500,
    },
  },
  linkContainer: {
    display: 'flex',
    position: 'absolute',
    bottom: 100,
    right: 0,
  },
  link: {
    fontWeight: 600,
    fontSize: theme.typography.h4.fontSize,
    margin: theme.spacing(0, 1),
    lineHeight: theme.typography.h4.lineHeight,
    [theme.breakpoints.down(1050)]: {
      fontSize: theme.typography.h5.fontSize,
    },
  },
  linkAccident: {
    color: theme.palette.secondary.main,
    textDecoration: 'underline',
    '&:hover': {
      color: theme.palette.primary.dark,
    },
  },
  linkSOS: {
    color: theme.palette.secondary.main,
    textDecoration: 'underline',
    '&:hover': {
      color: theme.palette.secondary.dark,
    },
  },
  linkSeparator: {
    color: theme.palette.secondary.main,
    [theme.breakpoints.down(1050)]: {
      fontSize: theme.typography.h5.fontSize,
    },
  },
}));

export default useStyles;

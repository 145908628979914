/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Container, Typography, Button, Link } from '@material-ui/core';

import Header from '../Header/Header';
import useStyles from './surgossegiSearchMobile.styles';
import Search from '../Search/Search'; '../Search/Search';

const SurgossegiSearchMobile = (props) => {
  const {
    menuItems = [],
    currentPage = {},
    parentPage = {},
    pageType = 'surgossegi',
    language,
    toggleLanguage,
    userIsLoggedIn,
  } = props;
  const { meta } = currentPage;
  //const { _hu_post_title: titleHu, _en_post_title: titleEn } = meta;
  //const { link: parentLink } = parentPage;
  const title = 'Keresés';
  const backUrl = location.search.substring(location.search.lastIndexOf('?backurl=')+9,location.search.lastIndexOf('&'));
  const term = location.search.substring(location.search.lastIndexOf('&term=')+6);
  const path = location.pathname.split('/').filter(path => path != '');
  const rightNav = { title: '', link: backUrl };
  const classes = useStyles({ pageType });

  return (
    <Container className={classes.root} maxWidth="xl" disableGutters>
      <Container className={classes.content} maxWidth="xl">
        <Header pageType={pageType} isMobile language={language} toggleLanguage={toggleLanguage} userIsLoggedIn={userIsLoggedIn} />
        <Box className={classes.links}>
          <Box>
            {/* <RouterLink to={backUrl} className={classes.parentLink}> */}
              <Button className={classes.parentButton} variant="outlined">
                <Typography variant="h4">
                  {title}
                </Typography>
              </Button>
            {/* </RouterLink> */}
          </Box>
          <Search data={{currentSearchTerm: term,searchRoute: '/surgossegikereses/?backurl='+backUrl+'&term='}}/>
        </Box>
      </Container>
    </Container>
  );
};

SurgossegiSearchMobile.propTypes = {
  menuItems: PropTypes.array,
  currentPage: PropTypes.object.isRequired,
  parentPage: PropTypes.object.isRequired,
  pageType: PropTypes.string.isRequired,
  language: PropTypes.string,
  toggleLanguage: PropTypes.func,
  userIsLoggedIn: PropTypes.bool,
};

export default SurgossegiSearchMobile;

import {
  REQUEST_LOGIN,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  SET_HAS_USER_SESSION_FROM_COOKIE,
  REQUEST_REGISTER,
  REGISTER_SUCCESS,
  REGISTER_ERROR,
  CLEAR_ERRORS,
} from '../constants/user';

const initialState = {
  isFetching: false,
  errorMessage: null,
  userIsLoggedIn: false,
  registerSuccess: false,
  userName: null,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_LOGIN: {
      return { ...state, isFetching: true };
    }
    case LOGIN_SUCCESS: {
      const { userName } = action;
      return {
        ...state,
        isFetching: false,
        errorMessage: null,
        userIsLoggedIn: true,
        userName,
      };
    }
    case LOGIN_ERROR: {
      const { error } = action;
      return {
        ...state,
        isFetching: false,
        errorMessage: error,
        userIsLoggedIn: false,
        userName: null,
      };
    }
    case SET_HAS_USER_SESSION_FROM_COOKIE: {
      const { hasSession, userName } = action;
      return {
        ...state,
        userIsLoggedIn: hasSession,
        userName,
      };
    }
    case REQUEST_REGISTER: {
      return {
        ...state,
        isFetching: true,
        registerSuccess: false,
      };
    }
    case REGISTER_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        errorMessage: null,
        registerSuccess: true,
      };
    }
    case REGISTER_ERROR: {
      const { error } = action;
      return {
        ...state,
        isFetching: false,
        registerSuccess: false,
        errorMessage: error,
      };
    }
    case CLEAR_ERRORS: {
      return {
        ...state,
        errorMessage: null,
      };
    }
    default:
      return state;
  }
};

export default userReducer;

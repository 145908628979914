/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Container, Typography } from '@material-ui/core';
import { useHistory } from 'react-router';
import Image from '../../Image/Image';
import useStyles from './FooterMobile.styles';

const getContent = (data, language) => {
  const { meta } = data;
  const { _hu_post_content: contentHu, _en_post_content: contentEn } = meta;
  return language === 'en' ? contentEn : contentHu;
};

const FooterMobile = ({ data, language, isLoading, isReady }) => {
  const { t } = useTranslation(['footer']);
  const history = useHistory();
  const classes = useStyles();

  return (
    <Container className={classes.root} maxWidth="md">
      <div onClick={() => history.push('/')}>
        <Image className={classes.logo} pictureClassName={classes.logo} src="eu_logo" alt="eu logo" />
      </div>
      <Container className={classes.wrapper} maxWidth="md">
        <div className={classes.topContent}>
          {isLoading ? <p>loading</p> : isReady ? <div dangerouslySetInnerHTML={{ __html: getContent(data, language) }} /> : <p>Error</p>}
        </div>
        <div className={classes.bottomContent}>
          <Typography variant="body1">{t('phoneTitle')}</Typography>
          <Typography className={classes.phoneNumber} variant="body1">{t('phoneNumber')}</Typography>
          <Image className={classes.heimPalLogo} pictureClassName={classes.heimPalLogo} src="logo" alt="logo" />
        </div>
      </Container>
    </Container>
  );
};

FooterMobile.propTypes = {
  language: PropTypes.string,
  data: PropTypes.object,
  isLoading: PropTypes.bool,
  isReady: PropTypes.bool,
};

export default FooterMobile;

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    minHeight: '100vh',
    backgroundColor: theme.palette.secondary.light,
  },
  cornerImage: {
    position: 'absolute',
    top: 0,
    right: 0,
    maxWidth: props => (props.isMobile ? 250 : 600),
  },
}));

export default useStyles;

import {
  REQUEST_GET_PAGE_CONTENT,
  GET_PAGE_CONTENT_SUCCESS,
  GET_PAGE_CONTENT_ERROR,
  REQUEST_GET_PAGE_CHILDREN_MENUS,
  GET_PAGE_CHILDREN_MENUS_SUCCESS,
  GET_PAGE_CHILDREN_MENUS_ERROR,
  REQUEST_GET_PAGE_PARENT,
  GET_PAGE_PARENT_SUCCESS,
  GET_PAGE_PARENT_ERROR,
  REQUEST_GET_FOOTER_CONTENT,
  GET_FOOTER_CONTENT_SUCCESS,
  GET_FOOTER_CONTENT_ERROR,
  CLEAR_ERRORS,
} from '../constants/page';

const initialState = {
  isFetching: false,
  errorMessage: null,
  content: null,
  currentPage: null,
  menuItems: [],
  parent: null,
  footer: null,
};

const pageReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_GET_PAGE_CONTENT: {
      return { ...state, isFetching: true };
    }
    case GET_PAGE_CONTENT_SUCCESS: {
      const { currentPage } = action;
      return {
        ...state,
        isFetching: false,
        currentPage,
      };
    }
    case GET_PAGE_CONTENT_ERROR: {
      const { error } = action;
      return {
        ...state,
        isFetching: false,
        errorMessage: error,
      };
    }
    case REQUEST_GET_PAGE_CHILDREN_MENUS: {
      return { ...state, isFetching: true, menuItems: [] };
    }
    case GET_PAGE_CHILDREN_MENUS_SUCCESS: {
      const { menuItems } = action;
      return {
        ...state,
        isFetching: false,
        menuItems,
      };
    }
    case GET_PAGE_CHILDREN_MENUS_ERROR: {
      const { error } = action;
      return {
        ...state,
        isFetching: false,
        errorMessage: error,
      };
    }
    case REQUEST_GET_PAGE_PARENT: {
      return { ...state, isFetching: true, parent: null };
    }
    case GET_PAGE_PARENT_SUCCESS: {
      const { parent } = action;
      return {
        ...state,
        isFetching: false,
        parent,
      };
    }
    case GET_PAGE_PARENT_ERROR: {
      const { error } = action;
      return {
        ...state,
        isFetching: false,
        errorMessage: error,
      };
    }
    case REQUEST_GET_FOOTER_CONTENT: {
      return { ...state, isFetching: true };
    }
    case GET_FOOTER_CONTENT_SUCCESS: {
      const { footerPage } = action;
      return {
        ...state,
        isFetching: false,
        footer: footerPage,
      };
    }
    case GET_FOOTER_CONTENT_ERROR: {
      const { error } = action;
      return {
        ...state,
        isFetching: false,
        errorMessage: error,
      };
    }
    case CLEAR_ERRORS: {
      return {
        ...state,
        errorMessage: null,
      };
    }
    default:
      return state;
  }
};

export default pageReducer;

/* eslint-disable */
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  drawer: {
    width: '100vw',
    backgroundColor: theme.palette.secondary.light,
    padding: theme.spacing(4),
  },
  iconButton: {
    height: 30,
  },
  menuIcon: {
    width: 30,
  },
  logo: {
    height: 30,
  },
  cornerImage: {
    position: 'absolute',
    top: 0,
    right: 0,
    maxWidth: '60%',
    display: 'flex',
    alignItems: 'center',
  },
  top: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  logoutButton: {
    marginRight: theme.spacing(1),
    color: theme.palette.secondary.dark,
    border: `2px solid ${theme.palette.secondary.dark}`,
    padding: theme.spacing(0, 1),
    borderRadius: 20,
  },
  linkWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  linkBlock: {
    display: 'block',
    margin: theme.spacing(1, 0),
  },
  link: {
    textDecoration: 'none',
    '&:hover,:active,:visited': {
      textDecoration: 'none',
    },
  },
  linkButton: {
    margin: theme.spacing(0.5, 0),
    borderRadius: 20,
    color: theme.palette.grey[800],
    borderColor: theme.palette.grey[800],
    padding: theme.spacing(0, 2),
    textTransform: 'none',
  },
  linkTitleSos: {
    width: '100%',
    backgroundColor: theme.palette.secondary.dark,
    color: theme.palette.secondary.light,
    textTransform: 'uppercase',
    '&:disabled': {
      color: theme.palette.secondary.light,
    },
  },
  linkTitleAccident: {
    width: '100%',
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.secondary.light,
    textTransform: 'uppercase',
    '&:disabled': {
      color: theme.palette.secondary.light,
    },
  },
  langButtonWrapper: {
    margin: theme.spacing(0.5, 0),
  },
  langButton: {
    float: 'right',
    borderRadius: 20,
    color: theme.palette.primary.dark,
    borderColor: theme.palette.primary.dark,
    padding: theme.spacing(0, 2),
    textTransform: 'none',
  },
  searchContainer: {
    width: '100%',
    float: 'left',
  },
  search:{
    width: '2.5rem',
    float: 'left'
  }
}));

export default useStyles;

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: '100vh',
    position: 'relative',
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    zIndex: theme.zIndex.drawer,
    minHeight: '100vh',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(10),
    padding: theme.spacing(0, 4),
    zIndex: theme.zIndex.drawer,
  },
  title: {
    color: props => (props.pageType === 'baleseti'
      ? theme.palette.primary.dark : theme.palette.secondary.dark),
  },
  wpContent: {
    color: props => (props.pageType === 'baleseti'
      ? theme.palette.primary.dark : theme.palette.secondary.dark),
    '& table': {
      borderCollapse: 'collapse',
    },
    '& td': {
      padding: theme.spacing(1),
      border: props => `1px solid ${props.pageType === 'baleseti'
        ? theme.palette.primary.dark : theme.palette.secondary.dark}`,
    },
  },
  link: {
    color: props => (props.pageType === 'baleseti'
      ? theme.palette.primary.main : theme.palette.secondary.main),
    textDecoration: 'none',
    '&:active,&:hover,&:visited': {
      textDecoration: 'none',
    },
    '&::before': {
      color: props => (props.pageType === 'baleseti'
        ? theme.palette.primary.dark : theme.palette.secondary.dark),
      content: '"• "',
    },
    '&:hover': {
      color: props => (props.pageType === 'baleseti'
        ? theme.palette.primary.dark : theme.palette.secondary.dark),
    },
  },
}));

export default useStyles;

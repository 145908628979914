import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { Box, Container, Link, Typography, FormGroup, FormControl, OutlinedInput, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';

import Header from '../Header/Header';
import Loading from '../Loading/Loading';
import useStyles from './LoginMobile.styles';

const LoginMobile = ({
  login,
  location = {},
  userIsLoggedIn,
  errorMessage,
  isFetching,
  handleLogin,
  validationSchema,
  language,
  toggleLanguage,
}) => {
  const { t } = useTranslation(['login']);
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema,
    onSubmit: (values) => {
      const { email, password } = values;
      handleLogin(email, password, login);
    },
  });
  const history = useHistory();

  const handleBackClick = () => {
    history.go(-2);
  };

  const classes = useStyles();

  useEffect(() => {
    if (userIsLoggedIn && !isFetching && !errorMessage) {
      const { state = {} } = location;
      const { redirectPath = '/' } = state;
      window.location.href = redirectPath;
    }
  }, [userIsLoggedIn, isFetching, errorMessage, location]);

  if (isFetching) return <Loading />;

  const controls = ['email', 'password'];

  return (
    <Container className={classes.root} maxWidth="xl" disableGutters>
      <Container className={classes.contentWrapper} maxWidth="xl">
        <Header pageType="login" isMobile language={language} toggleLanguage={toggleLanguage} />
        <Box className={classes.content}>
          <Typography className={classes.title} variant="h5">{t('title')}</Typography>
          <form onSubmit={formik.handleSubmit}>
            <FormGroup>
              {errorMessage && <Typography className={classes.errorMessage} variant="h6">{t('errorMessage')}</Typography>}
              {controls.map(control => (
                <FormControl key={control} variant="outlined">
                  <OutlinedInput
                    fullWidth
                    id={control}
                    name={control}
                    type={control}
                    placeholder={t(control)}
                    value={formik.values[control]}
                    onChange={formik.handleChange}
                    error={(formik.touched[control] && formik.errors[control]) || errorMessage}
                    classes={{
                      input: classes.outlinedInput,
                      notchedOutline: classes.notchedOutline,
                      focused: classes.notchedOutlineFocused,
                      error: classes.outlinedInputError,
                    }}
                    className={classes.textField}
                  />
                </FormControl>
              ))}
            </FormGroup>
            <Button className={classes.submitButton} variant="contained" type="submit" disableElevation>
              <Typography variant="h6">{t('submit')}</Typography>
            </Button>
            <Button onClick={handleBackClick} className={classes.goBackButton} variant="outlined" disableElevation>
              <Typography variant="h6">{t('back')}</Typography>
            </Button>
          </form>
          <Link className={classes.notRegistered} component={RouterLink} to="/register">
            <Typography variant="h5">{t('notRegistered')}</Typography>
          </Link>
        </Box>
      </Container>
    </Container>
  );
};

LoginMobile.propTypes = {
  login: PropTypes.func.isRequired,
  location: PropTypes.object,
  userIsLoggedIn: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string,
  handleLogin: PropTypes.func,
  validationSchema: PropTypes.object,
  language: PropTypes.string,
  toggleLanguage: PropTypes.func,
};

export default LoginMobile;

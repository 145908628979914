import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { Container, Typography, Box, Link } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import Image from '../../Image/Image';
import Header from '../Header/Header';
import useStyles from './ContentMobile.styles';

const ContentMobile = ({ userIsLoggedIn, language, toggleLanguage }) => {
  const classes = useStyles();
  const { t } = useTranslation(['homepage']);
  return (
    <Container maxWidth="md">
      <Header language={language} toggleLanguage={toggleLanguage} userIsLoggedIn={userIsLoggedIn} />
      <Box className={classes.content}>
        <Typography variant="h5" className={classes.title}>
          {t('title')}
        </Typography>
        <Box className={classes.linkContainer}>
          <Link component={RouterLink} className={`${classes.linkAccident} ${classes.link}`} to="/baleseti">
            {t('nav.accident')}
          </Link>
          <Typography variant="h5" className={classes.linkSeparator}>
            {t('nav.or')}
          </Typography>
          <Link component={RouterLink} className={`${classes.linkSOS} ${classes.link}`} to="/surgossegi">
            {t('nav.sos')}
          </Link>
        </Box>
        <Box className={classes.imageWrapper}>
          <Box><Image className={classes.image} src="home_bg" alt="background" /></Box>
        </Box>
      </Box>
    </Container>
  );
};

ContentMobile.propTypes = {
  userIsLoggedIn: PropTypes.bool.isRequired,
  language: PropTypes.string,
  toggleLanguage: PropTypes.func,
};

export default ContentMobile;

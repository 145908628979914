/* eslint-disable */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import NotFound from '../NotFound/NotFound';
import Loading from '../Loading/Loading';
import { wpStructureLevels, errorCodes } from '../../constants/constants';
import FirstLevel from '../FirstLevel/FirstLevel';
import SecondLevel from '../SecondLevel/SecondLevel';
import ThirdLevel from '../ThirdLevel/ThirdLevel';

const ContentManager = ({
  path, isFetching, errorMessage, getPageContent, currentPage, parentPage, menuItems, language, toggleLanguage, userIsLoggedIn,
}) => {
  const history = useHistory();
  useEffect(() => {
    if (path && errorMessage === null) {
      getPageContent(path);
    }
  }, [path, getPageContent, errorMessage]);

  if (!isFetching && errorMessage) {
    if (errorMessage === errorCodes.ACCESS_DENIED) {
      history.push({
        pathname: '/login',
        state: { redirectPath: path },
      });
    }
    return <NotFound errorMessage={errorMessage} />;
  }

  if (isFetching || !currentPage) return <Loading />;
  const { structureLevel, pageType } = currentPage;
  if (structureLevel === wpStructureLevels.firstLevel) {
    return (
      <FirstLevel
        language='hu'
        toggleLanguage={toggleLanguage}
        menuItems={menuItems}
        pageType={pageType}
        userIsLoggedIn={userIsLoggedIn}
      />
    ); // egetett tartalmak
  }

  if (isFetching || !parentPage) return <Loading />;
  if (structureLevel === wpStructureLevels.secondLevel) {
    return (
      <SecondLevel
        language='hu'
        toggleLanguage={toggleLanguage}
        currentPage={currentPage}
        menuItems={menuItems}
        parentPage={parentPage}
        pageType={pageType}
        userIsLoggedIn={userIsLoggedIn}
      />
    ); // dinamikus linkek
  }
  if (structureLevel === wpStructureLevels.thirdLevel) {
    return (
      <ThirdLevel
        toggleLanguage={toggleLanguage}
        language='hu'
        currentPage={currentPage}
        parentPage={parentPage}
        pageType={pageType}
        userIsLoggedIn={userIsLoggedIn}
        menuItems={menuItems}
      />
    ); // content
  }
  return <NotFound />;
};

ContentManager.propTypes = {
  path: PropTypes.string.isRequired,
  isFetching: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string,
  getPageContent: PropTypes.func.isRequired,
  currentPage: PropTypes.object,
  parentPage: PropTypes.object,
  menuItems: PropTypes.array,
  language: PropTypes.string,
  toggleLanguage: PropTypes.func,
  userIsLoggedIn: PropTypes.bool,
};

export default ContentManager;

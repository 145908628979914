import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  typography: {
    fontFamily: "'Open Sans', sans-serif",
  },
  palette: {
    primary: {
      light: '#F0FFF7',
      main: '#94C2A8',
      dark: '#4FBE9F',
    },
    secondary: {
      light: '#FFF5E8',
      main: '#BDA380',
      dark: '#D96550',
    },
  },
});

export default theme;
